/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.empty .feed {
  min-height: calc(100vh - 100px);

  background-color: #f2f2f2;

  border-radius: 10px;
}

.feed {
  max-width: 510px;
  margin: 0 auto;

  @media $landscapeTablet {
    max-width: none;
  }
}

.header {
  margin: 0 10px 16px;
  padding-top: 14px;

  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 22px;
  letter-spacing: 0;

  text-align: center;

  border-top: 1px solid #323232;

  @media $landscapeTablet {
    display: none;
  }
}

.header + .footer {
  visibility: hidden;
}

.item + .item .block {
  margin-top: 12px;
}

.banner {
  margin: 30px auto 15px;

  @media $desktop {
    margin: 40px auto 18px;
  }
}

.timestamp {
  margin: 0 0 12px;

  color: #262626;
  font-weight: 600;
  font-size: 15px;

  font-family: $secondaryFont;
  line-height: 18px;
  letter-spacing: 1px;

  text-align: center;
  text-transform: uppercase;

  @media $landscapeTablet {
    margin-bottom: 26px;

    font-size: 26px;
    line-height: 31px;

    letter-spacing: 1.35px;
  }
}

.wrapper {
  max-width: 825px;
  margin: 0 auto;
}

.container {
  padding: 13px 10px 10px;

  background-color: #f2f2f2;

  border-radius: 10px;

  @media $landscapeTablet {
    padding: 25px 10px 45px;
  }
}

.container + .container {
  margin-top: 15px;
}

.control {
  max-width: 825px;
  margin: 0 auto;
  padding: 0 0 10px;

  @media $landscapeTablet {
    padding: 0 0 45px;
  }
}

.footer {
  max-width: 510px;
  margin: -10px auto 0;
  padding: 10px 10px 0;

  text-align: center;

  background-color: #f2f2f2;

  border-radius: 0 0 10px 10px;

  @media $landscapeTablet {
    max-width: none;
    margin: -30px 0 0;
    padding: 0 10px;
  }
}

.informer {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  margin-bottom: 12px;
  padding: 11px 12px;

  font-weight: normal;

  background-color: #fff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media $landscapeTablet {
    display: none;
  }
}

.informer:empty,
.isInSearch .informer {
  display: none;
}
