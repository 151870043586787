/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  display: none;

  @media $landscapeTablet {
    position: sticky;
    top: 0;
    right: 0;
    z-index: $zIndex-3;

    display: block;

    margin-bottom: -45px;
  }
}

.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 100%;
  height: 45px;
  overflow: hidden;
}

.share {
  padding-left: 20px;
}

.share,
.ad {
  visibility: hidden;

  opacity: 0;
}

.close {
  width: 44px;
  height: 100%;
  margin-left: auto;
  padding-top: 1px;

  line-height: 40px;

  text-align: center;
}

.close .button {
  color: #bababa;
}

.isInCard .close .button {
  color: #b3b3b3;
}

.stuck .container {
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

.ad {
  position: absolute;
  right: 45px;
  z-index: 1;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  padding-top: 1px;

  color: #000;
  font-weight: 600;
  font-size: 14px;

  font-family: $secondaryFont;
  letter-spacing: 1px;
  text-transform: uppercase;

  cursor: pointer;
}

.adSource::before {
  position: absolute;
  top: 50%;

  width: 1px;
  height: 18px;

  background-color: #d8d8d8;
  transform: translateY(-50%);

  content: '';
}

.adSource::before {
  right: 0;
}

.adSource {
  position: relative;

  padding-right: 16px;

  color: $brandColor;
}

.stuck .share,
.stuck .ad {
  visibility: visible;

  opacity: 1;
}

.button {
  margin: 0;
  padding: 0;

  background-color: transparent;

  border-width: 0;

  cursor: pointer;

  appearance: none;
}

.dark .close .button {
  color: rgba(204, 204, 204, 0.3);
}

.stuck.dark .container {
  background-color: rgba(51, 51, 51, 0.9);
}

.dark .ad {
  color: #fff;
}

.dark .adSource {
  color: #d09959;
}

.dark .adSource::before {
  background: rgba(204, 204, 204, 0.3);
}
