/* stylelint-disable declaration-empty-line-before */

@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;

  @media $landscapeTablet {
    background: #fff;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #e7e7e7;
  }
}

.root:hover {
  @media $landscapeTablet {
    opacity: 0.8;
  }
}

.wrap {
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;

  padding: $gapSmall;

  @media $portraitTablet {
    padding: $gapMedium;
  }
}

.content {
  flex-grow: 1;

  margin-bottom: 18px;
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;

  color: #757575;

  line-height: 1;

  fill: $brandColor;
}

.is1to1 {
  text-align: center;

  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 4px #b78a54;
}

.is1to1 .wrap {
  align-items: center;

  @media $landscapeTablet {
    padding: 30px 45px 18px;
  }
}

.is1to1 .content {
  max-width: 1155px;

  @media $landscapeTablet {
    margin-bottom: 20px;
  }
}
