/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */

.promo {
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 10;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  padding: 14px 14px 13px;

  background-color: #fff;

  border-radius: 0 8px 8px 0;
  box-shadow: inset 1px 0 #e5e5e5;
}

.promo:empty::after {
  margin-left: 0;
}

.promo::after {
  display: block;
  flex-shrink: 0;

  width: 14px;
  height: 14px;
  margin-top: -1px;
  margin-left: 12px;

  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23D9D9D9' fill-rule='nonzero'%3E%3Cpath d='M11.523 10.304a.738.738 0 0 1 1.477 0v1.974c0 .951-.771 1.722-1.723 1.722H1.723A1.722 1.722 0 0 1 0 12.278V2.722C0 1.771.771 1 1.723 1h1.97a.738.738 0 1 1 0 1.476h-1.97a.246.246 0 0 0-.246.246v9.556c0 .136.11.246.246.246h9.554c.136 0 .246-.11.246-.246v-1.974zM12.465 1.535H7.768a.768.768 0 1 1 0-1.535h5.464c.424 0 .768.344.768.768v5.464a.768.768 0 0 1-1.535 0V1.535z'/%3E%3Cpath d='M12.715.22a.753.753 0 1 1 1.065 1.065L6.285 8.78A.753.753 0 0 1 5.22 7.715L12.715.22z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;

  content: '';
}

.logo {
  position: relative;

  width: 100%;
  max-width: 210px;
  margin-right: 11px;
}

.logo::after {
  position: absolute;
  top: 0;
  right: -11px;

  height: 16px;

  border-left: 1px solid #d9d9d9;

  content: '';
}

.logo img {
  display: block;

  max-width: 100%;
  height: 15px;
}

.external {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.externalLabel {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;

  border: 0;

  clip: rect(0 0 0 0);
}
