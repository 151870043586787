/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 46px;

  transform: translate(-50%, -50%);
}

.root svg {
  position: relative;
  z-index: 10;

  animation: bounce 1s cubic-bezier(0.51, 0.23, 0.02, 0.96) infinite;

  fill: #6c6;
}

.root::after {
  position: absolute;
  bottom: 1px;
  left: 1px;

  display: block;

  width: 100%;
  height: 15%;

  background: rgba(0, 0, 0, 0.5);

  border-radius: 100%;

  animation: shadow 1s infinite;

  content: '';
}

@keyframes bounce {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.85, 1.1) translateY(-15px);
  }
}

@keyframes shadow {
  0% {
    transform: scale(1, 1);
  }

  50% {

    background: rgba(0, 0, 0, 0.05);
    transform: scale(0.7, 1);
  }
}
