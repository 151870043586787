/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  color: #000;

  font-family: $primaryFont;

  background-color: #fff;

  -webkit-tap-highlight-color: transparent;

  @media $landscapeTablet {
    flex-direction: row;
  }
}

.root:hover {
  @media $landscapeTablet {
    z-index: 10;

    opacity: 0.95;
  }
}

.block {
  position: relative;
}

.link {
  display: block;
}

.body {
  z-index: 10;

  flex-grow: 1;

  width: 100%;
  padding: $gapSmall;

  @media $landscapeTablet {
    flex-basis: 60%;

    padding: $gapMedium;
  }
}

.simple .body {
  font-family: $primaryFont;

  @media $landscapeTablet {
    padding: 15px 20px;
  }
}

.figure {
  position: relative;

  margin: 0;

  border-radius: 8px 8px 0 0;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: 8px 8px 0 0;

  @media $landscapeTablet {
    border-radius: 8px 0 0 8px;
  }
}

.image::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0 0;

  content: '';

  @media $landscapeTablet {
    border-radius: 8px 0 0 8px;
  }
}

.tag {
  margin-bottom: 6px;

  color: $brandColor;

  line-height: 12px;

  @media $landscapeTablet {
    margin-bottom: 4px;
  }
}

.icon {
  margin-bottom: 12px;

  @media $landscapeTablet {
    margin-bottom: 3px;
  }
}

.icon img {
  display: block;
}

.simple .header h3 {
  @media $landscapeTablet {
    font-size: 27px;
    line-height: 33px;
  }
}

.header {
  margin-bottom: 18px;
}

.header h3 {
  font-size: 20px;
  line-height: 23px;

  @media $landscapeTablet {
    font-size: 21px;
    line-height: 27px;
  }
}

.header strong,
.header h3 {
  font-weight: normal;
}

.footer {
  margin-top: auto;

  color: #808080;

  font-size: 12px;
  line-height: 1;

  fill: $brandColor;
}

.episode strong,
.podcast strong,
.shapito strong,
.card strong,
.picture strong,
.video strong,
.rich strong,
.brief strong {
  font-weight: 700;
  font-size: 19px;
  font-family: $secondaryFont;

  @media $landscapeTablet {
    font-size: 20px;
  }
}

.rich .figure,
.podcast .figure,
.episode .figure,
.video .figure,
.picture .figure,
.card .figure {
  padding-top: 66.66%;

  @media $landscapeTablet {
    padding-top: 0;
  }
}

.card .figure,
.episode .figure,
.podcast .figure,
.video .figure,
.picture .figure,
.rich .figure {
  width: 100%;
  min-height: 160px;

  @media $landscapeTablet {
    width: 33.333%;
    min-height: 200px;

    border-radius: 8px 0 0 8px;
  }
}

.episode .figure,
.podcast .figure,
.card .figure {
  background-color: #262626;
}

.card .image {
  background-size: 120px;

  @media $landscapeTablet {
    margin: $gapSmall;

    background-size: contain;
  }
}

.episode .figure,
.podcast .figure {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.episode .image,
.podcast .image {
  width: 110px;
  height: 110px;
  margin: auto;

  border-radius: 6px;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.7);
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.root {
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.root .body {
  display: flex;
  flex-flow: column nowrap;
}

.bookmark {
  position: absolute;
  right: 14px;
  bottom: 10px;
  z-index: 10;
}
