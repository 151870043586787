/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 0 20px 23px;

  background-color: #fff;

  border-radius: 10px;

  @media $portraitTablet {
    padding: 0 15px 38px;
  }
}

.container {
  width: 100%;
}

.head {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  margin: 0 -20px 20px;
  padding: 38px 20px 20px;

  background-color: var(--bgColor);

  border-radius: 9px 9px 0 0;

  @media $portraitTablet {
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 50px;
  }
}

.materialHeader {
  position: relative;

  width: 100%;
  max-width: 650px;

  @media $portraitTablet {
    align-items: center;

    max-width: 860px;

    text-align: center;
  }
}

.article {
  position: relative;

  width: 100%;
}

.media {
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;
}

.body {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  @media $portraitTablet {
    display: flex;
    flex-flow: row nowrap;

    max-width: 1060px;
  }
}

.image {
  height: 160px;
  margin-bottom: 50px;

  @media $portraitTablet {
    height: 325px;
  }
}

.image img {
  display: block;

  width: auto;
  height: 100%;
  margin: 0 auto;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1),
    0 15px 15px 0 rgba(0, 0, 0, 0.09), 0 34px 20px 0 rgba(0, 0, 0, 0.05),
    0 60px 24px 0 rgba(0, 0, 0, 0.01), 0 94px 26px 0 rgba(0, 0, 0, 0);

  @media $portraitTablet {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 8px 16px 0 rgba(0, 0, 0, 0.1),
      0 30px 30px 0 rgba(0, 0, 0, 0.09), 0 68px 40px 0 rgba(0, 0, 0, 0.05),
      0 121px 48px 0 rgba(0, 0, 0, 0.01), 0 189px 52px 0 rgba(0, 0, 0, 0);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 650px;
  height: 42px;
  margin: 15px auto 0;

  color: #fff;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;

  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: #000;
  border-radius: 10px;

  @media $portraitTablet {
    margin-top: 40px;
  }
}

.button:hover {
  opacity: 0.8;
}

.isLight .button {
  color: #000;

  background-color: #fff;
}

.isLight .head {
  color: #fff;
}

.isDark .head {
  --tagColor: rgba(0, 0, 0, 0.6);
  --metaText: rgba(0, 0, 0, 0.6);
  --metaFill: currentColor;
}

.isLight .head {
  --tagColor: rgba(254, 255, 255, 0.5);
  --metaText: rgba(254, 255, 255, 0.5);
  --metaFill: currentColor;
}
