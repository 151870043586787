/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: none;

  @media $landscapeTablet {
    display: block;
  }
}

.container {
  @media $landscapeTablet {
    margin-bottom: 15px;
    padding: calc(45rem / $fontSize);

    background: #f2f2f2;

    border-radius: 10px;
  }
}

.banner {
  margin-bottom: 15px;
}

.container:last-child {
  margin-bottom: 0;
}

.header {
  @media $landscapeTablet {
    margin: -18px auto calc(24rem / $fontSize);
    padding: 0 $gapSmall;

    text-align: center;
  }
}

.header h2 {
  margin: 0;

  color: #262626;
  font-weight: 600;
  font-size: 26px;

  font-family: $secondaryFont;

  letter-spacing: 1px;
  text-transform: uppercase;
}
