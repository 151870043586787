/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */

@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  color: #000;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e5e5e5;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;
}

.root:hover {
  @media $landscapeTablet {
    opacity: 0.95;
  }
}

.imageWrap {
  position: relative;

  border-radius: 8px 8px 0 0;
}

.imageWrap::before {
  display: table;

  border-radius: inherit;

  content: '';

  @media $landscapeTablet {
    padding-bottom: 66.66%;
  }
}

.is1to1 .imageWrap,
.is1to2 .imageWrap {
  @media $landscapeTablet {
    position: static;

    border-radius: 8px;
  }
}

.picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: inherit;
}

.is1to3 .picture,
.is1to4 .picture {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.picture img {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;

  display: block;

  min-width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center top;

  transform: translateX(-50%);
}

.cover {
  display: flex;
  flex-flow: row wrap;

  width: 100%;

  @media $landscapeTablet {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.tag {
  display: flex;

  margin-bottom: 6px;

  color: $brandColor;

  line-height: 12px;

  @media $landscapeTablet {
    line-height: 14px;
  }
}

.contentWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;

  @media $landscapeTablet {
    position: absolute;
    top: 0;
    bottom: 0;

    justify-content: flex-end;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: $gapSmall;

  @media $landscapeTablet {
    flex-grow: 0;
    justify-content: flex-end;

    padding: 60px 15px 15px;
  }
}

.title {
  flex-grow: 1;

  margin-bottom: 18px;

  @media $landscapeTablet {
    flex-grow: 0;

    margin-bottom: 20px;
  }
}

.meta {
  color: rgba(0, 0, 0, 0.4);

  line-height: 14px;

  fill: #a7a7a7;

  @media $landscapeTablet {
    color: rgba(0, 0, 0, 0.4);
  }
}

.meta [data-meta='live'] {
  color: #e94522;
}

.is1to1 [data-meta='live'],
.is1to2 [data-meta='live'] {
  @media $landscapeTablet {
    color: rgba(255, 255, 255, 0.7);
  }
}

.is1to1 .meta,
.is1to2 .meta {
  @media $landscapeTablet {
    color: rgba(255, 255, 255, 0.7);

    fill: currentColor;
  }
}

.is1to1 .imageWrap::before {
  @media $landscapeTablet {
    height: calc(710rem / $fontSize);
    padding-bottom: 0;
  }
}

.is1to1.isRich .content,
.is1to2.isRich .content {
  @media $landscapeTablet {
    background-image: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 7%,
      rgba(0, 0, 0, 0.08) 12%,
      rgba(0, 0, 0, 0.16) 17%,
      rgba(0, 0, 0, 0.22) 21%,
      rgba(0, 0, 0, 0.32) 26%,
      rgba(0, 0, 0, 0.42) 32%,
      rgba(0, 0, 0, 0.52) 38%,
      rgba(0, 0, 0, 0.62) 47%,
      rgba(0, 0, 0, 0.72) 57%,
      rgba(0, 0, 0, 0.82) 65%
    );
  }
}

.is1to1.isRich .content {
  @media $landscapeTablet {
    align-items: center;

    min-height: calc(560rem / $fontSize);
  }
}

.is1to2.isRich .content {
  @media $landscapeTablet {
    min-height: 280px;
  }
}

.is1to1.isRich .title {
  text-align: center;

  @media $landscapeTablet {
    max-width: calc(1155rem / $fontSize);
    margin-bottom: 20px;
  }
}

.is1to3 .contentWrap,
.is1to4 .contentWrap {
  @media $landscapeTablet {
    position: static;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-top: 0;

    border-radius: 0 0 8px 8px;
  }
}

.is1to3 .content,
.is1to4 .content {
  @media $landscapeTablet {
    flex-grow: 1;

    padding: 14px 15px 15px;
  }
}

.is1to3 .title,
.is1to4 .title {
  @media $landscapeTablet {
    flex-grow: 1;
  }
}

.isRich.is1to1 .contentWrap::after,
.isRich.is1to2 .contentWrap::after {
  @media $landscapeTablet {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    border-radius: 9px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

    content: '';
  }
}

.is1to1.isRich .tag {
  @media $landscapeTablet {
    margin-bottom: 4px;
  }
}

.isCard {
  color: var(--textColor);

  background-color: var(--bgColor);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.isCard .tag {
  color: $brandColor;
}

.isCard.isMobile .imageWrap {
  padding-bottom: 66.66%;
}

.isCard.is1to1 {
  @media $landscapeTablet {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    height: 410px;
  }
}

.isCard.is1to1 .content {
  @media $landscapeTablet {
    max-width: 680px;
    padding: 0 0 0 15px;
  }
}

.isCard.is1to1 .imageWrap {
  @media $landscapeTablet {
    width: 39.2771084337%;
  }
}

.isPodcast.is1to1 .imageWrap::before,
.isPodcast.is1to2 .imageWrap::before,
.isCard.is1to1 .imageWrap::before {
  @media $landscapeTablet {
    content: none;
  }
}

.isCard.is1to1 .contentWrap {
  @media $landscapeTablet {
    position: static;

    width: 60%;
    padding-top: 10px;

    box-shadow: none;
  }
}

.isCard.is1to2 .imageWrap {
  @media $landscapeTablet {
    flex-grow: 1;
  }
}

.isCard.is1to2 .content {
  background-image: linear-gradient(
    180deg,
    rgba(24, 24, 24, 0) 7%,
    rgba(24, 24, 24, 0.08) 9%,
    rgba(24, 24, 24, 0.16) 11%,
    rgba(24, 24, 24, 0.22) 13%,
    rgba(24, 24, 24, 0.32) 15%,
    rgba(24, 24, 24, 0.42) 17%,
    rgba(24, 24, 24, 0.52) 20%,
    rgba(24, 24, 24, 0.62) 23%,
    rgba(24, 24, 24, 0.82) 27%,
    #181818 31%
  );
}

.isCard.withTheme .content {
  background-image: none;
}

.isPodcast .meta,
.isCard .meta {
  color: rgba(255, 255, 255, 0.7);

  fill: currentColor;
}

.isPodcast .meta {
  padding-bottom: 26px;
}

.isCard .meta {
  color: var(--metaColor);

  fill: currentColor;
}

.isPodcast {
  background-color: #1a1a1a;

  @media $landscapeTablet {
    height: auto;
  }
}

.isPodcast .tag {
  color: rgba(255, 255, 255, 0.6);
}

.isPodcast .title {
  color: #fff;
}

.isPodcast .imageWrap {
  display: flex;
  flex-flow: nowrap;

  width: 100%;

  padding-top: 38px;
  padding-bottom: 35px;

  @media $landscapeTablet {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.isPodcast .content {
  align-items: center;
  padding-top: 15px;

  text-align: center;
}

.isPodcast.is1to1 .cover,
.isPodcast.is1to2 .cover {
  position: static;
}

.isPodcast.is1to1 .content {
  @media $landscapeTablet {
    padding-top: 54px;
    padding-bottom: 12px;
  }
}

.isPodcast.is1to2 .content {
  @media $landscapeTablet {
    padding-top: 56px;
    padding-bottom: 15px;
  }
}

.isPodcast.is1to1 .imageWrap {
  @media $landscapeTablet {
    padding: 50px 0 0;
  }
}

.isPodcast.is1to1 .contentWrap, .isPodcast.is1to2 .contentWrap {
  @media $landscapeTablet {
    position: static;

    justify-content: flex-end;
  }
}

.isPodcast.is1to2 .imageWrap {
  @media $landscapeTablet {
    padding: 60px 0 0;
  }
}

.isPodcast.is1to3 .imageWrap::before,
.isPodcast.is1to4 .imageWrap::before {
  padding-bottom: 66.66%;
}

.isPodcast .imageWrap::before {
  padding-bottom: 0;

  @media $landscapeTablet {
    padding-bottom: 66.66%;
  }
}

.isPodcast.is1to1 .tag {
 @media $landscapeTablet {
  margin-bottom: 8px;
 }
}

.isPodcast.is1to1 .meta, 
.isPodcast.is1to2 .meta, 
.isPodcast.is1to3 .meta,
.isPodcast.is1to4 .meta {
  @media $landscapeTablet {
    padding-bottom: 0;
  }
}

.light.is1to1,
.light.is1to2 {
  @media $landscapeTablet {
    color: #fff;
  }
}

.light.is1to1 .tag,
.light.is1to2 .tag {
  @media $landscapeTablet {
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
}

.light.is1to1 .title,
.light.is1to2 .title {
  @media $landscapeTablet {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
}

.dark.is1to1,
.dark.is1to2 {
  @media $landscapeTablet {
    color: #000;
  }
}

.dark.is1to1 .meta,
.dark.is1to2 .meta {
  @media $landscapeTablet {
    color: #757575;
  }
}

.dark.is1to1 .tag,
.dark.is1to2 .tag {
  @media $landscapeTablet {
    color: #000;
  }
}

.isCard .picture {
  top: 50%;
  left: 50%;

  display: block;

  width: 180px;
  height: 180px;

  box-shadow: none;

  transform: translate(-50%, -50%);

  @media $landscapeTablet {
    width: 140px;
    height: 140px;
  }
}

.isCard.is1to2 .picture {
  @media $landscapeTablet {
    top: 40px;

    transform: translateX(-50%);
  }
}

.isCard.is1to4 .picture {
  @media $landscapeTablet {
    width: 120px;
    height: 120px;
  }
}

.isCard.is1to1 .picture {
  position: relative;
  top: 0;
  left: 0;

  width: 250px;
  height: 250px;
  margin-right: 15px;
  margin-left: auto;

  transform: none;
}

.bookmark {
  position: absolute;
  right: 16px;
  bottom: 8px;
  z-index: 10;

  transition: opacity 350ms ease;

  @media $landscapeTablet {
    right: 10px;
    bottom: 8px;

    transform: scale(0);
  }
}

.root:hover .bookmark {
  @media $landscapeTablet {
    animation: show 350ms ease both;
  }
}

@keyframes show {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
