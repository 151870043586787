@import '../../../styles/vars.css';

.root {
  position: sticky;
  bottom: 0;
  z-index: 120;

  display: none;
  flex-flow: column nowrap;
  align-items: center;

  margin-top: -20px;
  margin-bottom: 40px;

  font-family: $secondaryFont;

  @media $landscapeTablet {
    margin-top: 0;
    margin-bottom: -40px;
  }
}

.container {
  width: 100%;
  max-width: $layoutWidth;
  padding-top: 20px;

  @media $landscapeTablet {
    padding: 20px;
  }
}

.root.isRendered {
  display: flex;

  visibility: hidden;
}

.isVisible.isRendered {
  visibility: visible;

  animation: slideIn 1s cubic-bezier(1, -0.4, 0.35, 0.95);
}

.panel {
  position: relative;
  z-index: 10;

  display: flex;
  flex-flow: column nowrap;

  max-height: 100svh;

  padding-bottom: 20px;

  background-color: #d76d8a;
  background-image: url('https://meduza.io/image/attachments/images/010/699/871/original/WVXqUCQLk9mXWJlBt71dVg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: 20px;

  transform: translateY(20px);

  @media $landscapeTablet {
    padding-bottom: 0;

    border-radius: 40px;

    transform: translateY(0);
  }
}

.panel::after {
  position: absolute;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: inherit;

  content: '';
  pointer-events: none;

  inset: 0;
}

.dismiss {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;

  width: 18px;
  height: 18px;
  padding: 0;

  color: #fff;

  background-color: transparent;
  border-width: 0;
  box-shadow: none;

  cursor: pointer;

  appearance: none;
  fill: transparent;

  @media $landscapeTablet {
    top: 25px;
    right: 25px;
  }
}

.dismiss:hover {
  opacity: 0.8;
}

.dismiss svg {
  display: block;
}

.simple.isRendered {
  position: relative;

  visibility: visible;

  animation: none;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
