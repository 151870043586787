/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  background-color: #f2f2f2;

  border-radius: 11px 11px 10px 10px;
  box-shadow: 0 0 0 1px #272727;
}

.head {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 45px 20px 13px;

  background-color: var(--bgColor);

  border-radius: 10px 10px 0 0;

  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);

  @media $portraitTablet {
    align-items: center;
    justify-content: center;

    padding: 40px 15px 30px;
  }
}

/* hotfix card color */
.head [data-testid='document-items-count'] svg {
  fill: currentColor;
}

.affiliate:first-child {
  margin-bottom: $gapSmall;

  @media $portraitTablet {
    margin-bottom: $gapMedium;
  }
}

.header {
  max-width: 840px;

  color: var(--textColor);

  @media $portraitTablet {
    text-align: center;
  }
}

.body {
  display: flex;
  flex-flow: row nowrap;

  max-width: 980px;
  margin: 0 auto;
  padding: 10px 0;

  @media $portraitTablet {
    padding: 15px 0;
  }
}

.footer {
  width: 100%;
  max-width: 630px;
  margin-right: auto;
  margin-left: auto;
  padding: 25px 25px 22px;

  background: #fff;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 2px;

  @media $landscapeTablet {
    max-width: none;
  }
}

.sticky {
  display: none;

  @media $landscapeTablet {
    position: sticky;
    top: 60px;

    display: block;

    height: 0%; /* hack */
  }
}

.aside {
  display: none;
  flex-shrink: 0;

  width: 335px;
  margin-right: 15px;

  @media $landscapeTablet {
    display: block;
  }
}

.main {
  width: 100%;
  padding: 0 10px;

  @media $landscapeTablet {
    padding: 0;
  }
}

.nav {
  position: sticky;
  top: 60px;

  margin: 0;
  padding: 20px 15px 19px;

  overflow-x: hidden;

  list-style: none;
  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 4px 0 #b88b58;

  counter-reset: list 0;
}

.navItem {
  display: flex;
  flex-flow: row nowrap;

  margin-bottom: 9px;

  font-weight: 600;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 22px;
}

.navItem:hover {
  color: #5c5c5c;

  cursor: pointer;
}

.navItem:last-child {
  margin-bottom: 0;
}

.navItem::before {
  min-width: 29px;

  color: #b88b58;
  font-weight: 700;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 22px;

  content: counter(list) '. ';
  counter-increment: list;
}

.content {
  width: 100%;
  max-width: 630px;
  margin: 0 auto;
}

.card {
  margin-bottom: 10px;
  padding: 8px 12px 16px;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e5e5e5;

  @media $portraitTablet {
    margin-bottom: 15px;
    padding: 15px 25px 25px;
  }
}

.card:last-child {
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;

  @media $landscapeTablet {
    padding-top: 16px;
    padding-bottom: 18px;
  }
}

.image {
  width: 150px;
  margin: 0 auto 30px;

  aspect-ratio: 1 / 1;
}

.image img {
  display: block;

  max-width: 100%;
}

.bookmark {
  position: absolute;
  right: 9px;
  bottom: 6px;
  z-index: 10;

  opacity: 0.2;

  @media $mobile {
    display: none;
  }
}

.share {
  padding-top: 10px;

  @media $mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
  }
}
