/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  margin-bottom: 30px;

  text-align: center;

  @media $landscapeTablet {
    margin-bottom: 0;
  }
}

.root svg {
  vertical-align: top;
}

.mobile {
  @media $landscapeTablet {
    display: none;
  }
}

.desktop {
  display: none;
  @media $landscapeTablet {
    display: inline;
  }
}
