/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  max-width: 650px;
  margin: 16px 0;
  padding: 12px;

  background: #f5f5f5;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media $portraitTablet {
    margin: 25px auto 20px;
    padding: 24px;
  }
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}

.header {
  display: flex;
  flex-flow: column nowrap;

  margin-bottom: 24px;
}

.tag {
  display: block;

  margin-bottom: 24px;

  color: #000;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 12px;

  letter-spacing: 1px;
  text-transform: uppercase;

  opacity: 0.4;
}

.logo svg {
  display: block;

  width: 230px;
  height: auto;

  @media $portraitTablet {
    width: 385px;
  }
}

.form label {
  position: absolute;
  z-index: -1;

  visibility: hidden;
}

.subscribe {
  position: relative;
}

.message a {

  color: inherit;
  text-decoration: underline;
}

.button {
  width: 100%;
  height: 56px;

  color: #fff;
  font-size: 19px;

  font-family: $secondaryFont;
  line-height: 1;

  background: #ee365e;
  border-width: 0;
  border-radius: 72px;

  cursor: pointer;

  appearance: none;

  @media $portraitTablet {
    position: absolute;
    top: 0;
    right: 0;

    width: 209px;
    height: 72px;
  }
}

.button:hover {
  background: linear-gradient(0deg,
  rgba(255, 255, 255, 0.08),
  rgba(255, 255, 255, 0.08)),
    #ee365e;
}

.button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #ee365e;
}

.button:disabled {

  color: rgba(255, 255, 255, 0.4);

  pointer-events: none;
}

.content {
  margin-bottom: 40px;
}

.title {
  margin: 0 0 8px;

  font-weight: 700;
  font-size: 40px;

  font-family: $secondaryFont;
  line-height: 40px;

  background: linear-gradient(91.96deg,
  #ee3694 -78.98%,
  #ec365d -65.68%,
  #e6345b -52.38%,
  #db3256 -39.08%,
  #cb2e50 -25.78%,
  #b72948 -12.47%,
  #9f243f 0.83%,
  #851e34 14.13%,
  #69182a 27.43%,
  #4f121f 40.73%,
  #370d16 54.03%,
  #23080e 67.33%,
  #130408 80.63%,
  #080203 93.93%,
  #020001 107.23%,
  #000 120.53%);
  background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}

.text {
  width: 288px;

  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 28px;

  @media $portraitTablet {
    width: auto;
  }
}

.text p {
  margin: 0;
}

.text p + p {
  margin-top: 12px;
}

.form {
  position: relative;
}

.input {
  width: 100%;
  height: 56px;
  margin-bottom: 8px;

  font-size: 20px;

  font-family: $secondaryFont;

  text-indent: 24px;

  border-width: 0;
  border-radius: 72px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);

  @media $portraitTablet {
    height: 72px;
    margin-bottom: 0;

    font-size: 20px;
  }
}

.input:focus {
  box-shadow: 0 0 0 2px #000;
}

.footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-end;

  min-height: 75px;
  margin-top: 4px;

  @media $portraitTablet {
    align-items: flex-start;

    max-width: 450px;
    min-height: 45px;
    margin-top: 8px;
  }
}

.message,
.description {
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;
  text-align: center;

  @media $portraitTablet {
    text-align: left;
  }
}

.message {
  display: block;

  color: #ea4335;
}

.description {
  color: rgba(0, 0, 0, 0.4);
}

.description p {
  margin: 0;
}

.description a {
  color: inherit;

  box-shadow: inset 0 -1px currentColor;
}

.description a:hover {
  color: $brandColor;
}

.root.isSuccess .input,
.root.isSuccess .button {
  opacity: 0;
}

.invalid .input {
  box-shadow: 0 0 0 2px #ff1f00;
}

.success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0 8px;

  font-size: 24px;
  font-family: $secondaryFont;
  line-height: 28px;

  text-align: center;

  background-color: #f7f7f7;

  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
