/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  padding-top: 38px;

  color: #fff;

  text-align: left;

  background-color: #000;
}

.body {
  position: relative;
  z-index: $zIndex-4;

  padding: 0 12px;
}

.bodyWithAmbiance {
  top: -40px;

  margin-bottom: -40px
}

.bodyInEmbed {
  padding: 0 15px;

  @media $mobile {
    padding: 0 20px;
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background-position: center;
  background-size: cover;

  transform: scale(1.5);
  transform-origin: center;

  opacity: 0.48;
}

.image {
  position: relative;
  right: 0;
  left: 0;
  z-index: $zIndex-1;

  width: 160px;
  height: 160px;
  margin: 0 auto 36px;
  margin-bottom: 50px;

  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  border-radius: 1px;

  box-shadow: 
  0 0.46px 3.27px 0 rgba(0, 0, 0, 0.06),
  0 2.04px 6.76px 0 rgba(0, 0, 0, 0.1),
  0 5px 13.49px 0 rgba(0, 0, 0, 0.13),
  0 9.63px 26.44px 0 rgba(0, 0, 0, 0.16),
  0 16.2px 48.61px 0 rgba(0, 0, 0, 0.2),
  0 25px 83px 0 rgba(0, 0, 0, 0.26);

  @media $landscapeTablet {
    margin-bottom: 40px;
  }
}

.imageInMaterial {
  @media $portraitTablet {
    margin-bottom: 40px;
  }
}

.hiddenLink::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-2;

  content: '';
}

.root h1 {
  font-size: 19px;
  line-height: 23px;
}

.ambiance {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.gradientImage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $zIndex-1;

  width: 100%;
  height: 100%;

  background: radial-gradient(67.05% 68.56% at 89.3% 7.36%, #FFF 5.9%, rgba(255, 255, 255, 0.991615) 13.79%, rgba(255, 255, 255, 0.967585) 20.21%, rgba(255, 255, 255, 0.9296) 25.39%, rgba(255, 255, 255, 0.879348) 29.56%, rgba(255, 255, 255, 0.818519) 32.93%, rgba(255, 255, 255, 0.7488) 35.73%, rgba(255, 255, 255, 0.671881) 38.2%, rgba(255, 255, 255, 0.589452) 40.55%, rgba(255, 255, 255, 0.5032) 43.02%, rgba(255, 255, 255, 0.414815) 45.82%, rgba(255, 255, 255, 0.325985) 49.19%, rgba(255, 255, 255, 0.2384) 53.35%, rgba(255, 255, 255, 0.153748) 58.53%, rgba(255, 255, 255, 0.0737185) 64.96%, rgba(255, 255, 255, 0) 72.85%);
  opacity: 0.2;
}

.gradientBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  height: 36%;
}

.withAmbiance {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding-top: 0;
  overflow: hidden;
}

.withAmbiance .image {
  position: absolute;
  top: 12px;
  right: 12px;
  left: auto;

  width: 50px;
  height: 50px;


  @media $landscapeTablet {
    width: 109px;
    height: 109px;
    margin-bottom: 40px;
  }
}

.rootInMaterial .image {
  @media $portraitTablet {
    margin-bottom: 40px;
  }
}

.withAmbiance .background {
  display: none;
}

.isInDynamicBlock {
  border-radius: 8px;
}

.isInDynamicBlock .ambiance {
  overflow: hidden;

  border-radius: inherit;
}

.rootInMaterial {
  border-radius: 10px 10px 0 0;

  @media $portraitTablet {
    padding-top: 60px;
  }
}

.rootInEmbed {
  max-width: 650px;

  margin: 16px auto;

  @media $portraitTablet {
    margin: 25px auto;
  }

  border-radius: 10px;
}

.bodyInMaterial {
  padding: 0 20px;

  @media $mobile {
    max-width: 690px;
    margin-right: auto;
    margin-left: auto;
  }

  @media $portraitTablet {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
  }
}

.audioPanel {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  @media $landscapeTablet {
    gap: 10px;
    padding-bottom: 28px;
  }
}

.audioPanelInMaterial {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  
  @media $portraitTablet {
    gap: 10px;
    padding-bottom: 28px;
  }
}

.audioPanelInEmbed {
  padding-top: 8px;
  @media $portraitTablet {
    gap: 0;
    margin-top: -2px;
    padding-top: 0;
    padding-bottom: 12px;
  }
}

.mainPanel {
  display: flex;
  gap: 10px;
  align-items: center;
}

.additionalPanel {
  display: flex;
  gap: 10px;
  align-items: center;
}

.additionalPanelInMaterial {
  @media $portraitTablet {
    display: none;
  }
}

.tagContainer {
  margin-bottom: 6px;

  opacity: 0.5;

  @media $landscapeTablet {
    margin-bottom: 10px;
  }
}

.tagContainerInMaterial {
  @media $portraitTablet {
    margin-bottom: 10px;
  }
}

.tagContainerInEmbed {
  @media $landscapeTablet {
    margin-bottom: 6px;
  }
}

.play {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin-right: 6px;
  margin-left: auto;
}

.button {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  color: #969696;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: currentColor;
}

.buttonPlay {
  width: 36px;
  height: 36px;

  border-radius: 50%;
  outline: none;
  box-shadow: inset 0 0 0 2px #b3b3b3;
}

.buttonPlayLight {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
}

.buttonPlayDark {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.25);
}

.metaContainer {
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  transform: translateZ(0);

  opacity: 0.5;

  fill: inherit;
}

.bookmark {
  width: 30px;
  height: 30px;
}
