@import '../../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: row nowrap;

  --color: #fff;

  gap: 10px;
  --colorHover: #fff;
  --bgColor: #d9d9d9;
  --bgColorHover: #bfbfbf;
}

.root.dark {
  --color: #606060;

  --colorHover: #989898;
  --bgColor: #303030;
  --bgColorHover: #303030;
}

.button {
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  color: var(--color);

  background-color: var(--bgColor);
  border-width: 0;
  border-radius: 50%;

  cursor: pointer;

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  -webkit-tap-highlight-color: transparent;
}

.button svg path {
  transition: stroke 0.3s ease-in-out;
}

.button:hover {
  color: var(--colorHover);

  background-color: var(--bgColorHover);
}
