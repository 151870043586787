/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: relative;

  width: 100%;
  max-width: 510px;
  margin: 0 auto;

  @media $landscapeTablet {
    max-width: $layoutWidth;
  }
}

.desktopOnly {
  display: none;
  @media $landscapeTablet {
    display: block;
  }
}

.layout {
  padding: 10px;
  overflow: hidden;

  background-color: #f2f2f2;

  border-radius: 10px;

  transform: translateZ(0);

  @media $landscapeTablet {
    padding: 2.25rem;
  }
}

.animation {
  position: relative;
  z-index: 10;

  box-sizing: content-box;
  height: 67px;

  overflow: hidden;

  border-color: #fff;
  border-style: solid;

  border-width: 15px 15px 20px;

  @media $landscapeTablet {
    height: 93px;

    border-width: 30px 12px;
  }
}

.animation span {
  position: absolute;
  z-index: 3;
}

.animation span:nth-child(1) {
  top: 0;
  right: 0;
  left: 0;

  height: 17px;

  overflow: hidden;

  @media $landscapeTablet {
    height: 23px;
  }
}

.animation span:nth-child(1)::after,
.animation span:nth-child(1)::before,
.animation span:nth-child(3)::after,
.animation span:nth-child(3)::before,
.animation span:nth-child(5)::after,
.animation span:nth-child(5)::before {
  position: absolute;

  height: 100%;

  content: '';
}

.animation span:nth-child(1)::after,
.animation span:nth-child(3)::after,
.animation span:nth-child(5)::after {
  left: 0;

  width: 82.8125%;

  border-radius: 50px;
  box-shadow: 0 0 0 10px #fff;
}

.animation span:nth-child(1)::before,
.animation span:nth-child(5)::before {
  top: 0;
  right: 0;
  left: 82.8125%;

  background: #fff;
}

.animation span:nth-child(3) {
  top: 25px;
  right: 0;
  left: 0;

  height: 17px;

  overflow: hidden;

  @media $landscapeTablet {
    top: 35px;

    height: 23px;
  }
}

.animation span:nth-child(3)::after {
  width: 100%;
}

.animation span:nth-child(5) {
  top: 50px;
  right: 0;
  left: 0;

  height: 17px;

  overflow: hidden;

  @media $landscapeTablet {
    top: 70px;

    height: 23px;
  }
}

.animation span:nth-child(5)::after {
  width: 70.3125%;
}

.animation span:nth-child(5)::before {
  left: 70.3125%;
}

.animation span:nth-child(2),
.animation span:nth-child(4) {
  right: 0;
  left: 0;

  height: 8px;

  background: #fff;

  @media $landscapeTablet {
    height: 12px;
  }
}

.animation span:nth-child(2) {
  top: 17px;

  @media $landscapeTablet {
    top: 23px;
  }
}

.animation span:nth-child(4) {
  top: 42px;

  @media $landscapeTablet {
    top: 58px;
  }
}

.card {
  position: relative;
  z-index: 10;

  width: 100%;

  overflow: hidden;

  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 0 12px #f2f2f2;
}

[data-route='material'] .root {
  @media $landscapeTablet {
    padding-top: 60px;
  }
}

[data-route='material'] .root::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  height: 45px;

  background-color: #fff;

  border-radius: 10px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);

  @media $landscapeTablet {
    display: block;

    content: '';
  }
}

.isInMaterial .layout {
  min-height: calc(100vh - 102px);
  padding: 0;

  background-color: #fff;

  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  @media $landscapeTablet {
    padding-top: 45px;
  }
}

.isInMaterial .animation {
  border-bottom: 0;
}

.isInMaterial .animation:first-child {
  height: 42px;

  @media $landscapeTablet {
    height: 70px;
  }
}

.isInMaterial .wrapper {
  max-width: 836px;
}

.wrapper {
  position: relative;
  z-index: 10;

  max-width: 540px;
  margin: 0 auto;

  background-color: #f2f2f2;

  @media $landscapeTablet {
    max-width: $layoutWidth;
  }
}

.wrapper::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  height: 100%;

  background-color: #f7f7f7;
  background-repeat: no-repeat;
  transform: translateZ(0);

  animation-name: materialSkeleton;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  animation-fill-mode: forwards;

  content: '';
}

.section {
  position: relative;
  z-index: 10;

  margin-bottom: 12px;

  @media $landscapeTablet {
    margin-bottom: 15px;
  }
}

.section:last-child {
  margin-bottom: 0;
}

.media .card::before {
  display: block;

  padding-bottom: 66.66%;

  content: '';
}

.media.is1to2 .card::before {
  @media $landscapeTablet {
    padding-bottom: calc(66.66% - 67px);

    background-color: #fff;
  }
}

.media .card + .card {
  margin-left: 15px;
}

.topic .card {
  border-bottom-width: 0;
  border-radius: 0;
  box-shadow: none;
}

.topic .card:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.topic .card:last-child {
  border-bottom-width: 1px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mobile {
  @media $landscapeTablet {
    display: none;
  }
}

.desktop {
  display: none;

  @media $landscapeTablet {
    display: flex;
  }
}

.media {
  @media $landscapeTablet {
    flex-flow: row nowrap;
  }
}

.isInChronology .wrapper {
  max-width: 825px;
}

.isInChronology .section {
  margin-bottom: 0;
}

.isInChronology .section::after {
  display: block;

  height: 12px;

  background-color: #f2f2f2;

  content: '';

  @media $landscapeTablet {
    height: 15px;
  }
}

.isInChronology .section:last-child::after {
  content: none;
}

.isInChronology .card {
  box-shadow: 0 0 0 2px #f2f2f2;
}

@keyframes materialSkeleton {
  0% {
    right: 100%;
    left: 0;
  }

  50% {
    right: 0;
    left: 0;
  }

  100% {
    right: 0;
    left: 100%;
  }
}
