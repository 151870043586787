/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-2;

  margin-top: $gapSmall;

  @media $mobile {
    margin-top: 45px;
  }
}

.root:empty {
  margin-top: 0;
}

.banner {
  margin-bottom: 12px;
  padding-top: 18px;

  @media $desktop {
    margin-bottom: 26px;
    padding-top: 0;
  }
}
