/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;
}

.root:hover {
  opacity: 0.8;
}

.header {
  flex-grow: 1;
}

.tag {
  display: inline;

  margin-right: 7px;

  color: $brandColor;

  line-height: 1;
}

.footer {
  position: relative;
  z-index: $zIndex-1;

  color: #757575;

  line-height: 14px;

  transform: translateZ(0);

  fill: #757575;
}

.footer:not(:empty) {
  margin-top: 12px;
}

.footer [data-meta='live'] {
  color: #e94522;
}

.external {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;

  content: '';
}

.featured .tag {
  display: block;

  margin-bottom: 6px;

  line-height: 16px;
}

.bookmark {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;

  color: #000;

  @media $landscapeTablet {
    transform: scale(0);

    opacity: 0;
  }
}

.root:hover .bookmark {
  @media $landscapeTablet {
    animation: show 350ms ease both;
  }
}

@keyframes show {
  0% {
    transform: scale(0);

    opacity: 0;
  }

  60% {
    transform: scale(1.2);

    opacity: 0.2;
  }

  100% {
    transform: scale(1);

    opacity: 0.2;
  }
}
