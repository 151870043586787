.root {
  display: block;

  color: inherit;
}

.isInProfile {
  position: relative;
}

.isInBlockTitle {
  display: inline;
}

.isInBlockTitle:focus span {
  opacity: 0.75;
}

.isInBlockTitle::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  content: '';
}
