@import '../../../../styles/vars.css';

.button {
  position: sticky;
  top: 20px;
  z-index: 101;

  display: block;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;

  visibility: hidden;
  cursor: pointer;

  @media $landscapeTablet {
    top: 60px;

    order: 1;
    margin-right: initial;
    margin-left: initial;
  }
}

.buttonCenter {
  @media $landscapeTablet {
    top: 60px;

    order: 1;
    margin-right: auto;
    margin-left: auto;
  }
}

.buttonCard {
  max-width: 630px;
}

.link {
  position: sticky;
  top: 20px;
  z-index: 1;

  display: flex;
  justify-content: flex-end;
  width: 42px;
  height: 42px;
  margin-top: -62px;
  margin-bottom: 20px;
  margin-left: auto;

  background: #f0f0f0;
  border-radius: 100%;
  visibility: visible;
  cursor: pointer;

  @media $landscapeTablet {
    top: 60px;

    margin-top: -72px;
    margin-bottom: 30px;
    margin-left: 665px;
  }
}

.linkCard {
  margin-top: -53px;
  margin-right: 12px;
  margin-bottom: 10px;

  background: #f2f2f2;

  @media $portraitTablet {
    margin-top: -58px;
    margin-bottom: 15px;
  }

  @media $landscapeTablet {
    margin-left: 645px;

    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.modalLink {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 999;

  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;

  @media $contentWidth {
    top: 0;
    right: 0;
  }

  @media $landscapeTablet {
    position: sticky;
    top: 60px;
    z-index: 1;

    order: 1;
    margin-left: 15px;
  }
}

.modalLinkCenter {
  @media $landscapeTablet {
    margin-left: 665px;
  }
}

.modalLinkCard {
  right: 22px;

  @media $contentWidth {
    right: 12px;
  }

  @media $landscapeTablet {
    margin-left: 645px;
  }
}

.icon {
  margin-top: 14px;
  margin-right: 14px;
}

.iconCard {
  @media $landscapeTablet {
    margin-top: 13px;
    margin-right: 13px;
  }
}
