/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.share {
  max-width: 650px;
  margin: 0 auto;
}

.isInCard {
  padding: 5px 0;
}

.rich:last-child,
.simple:last-child {
  padding-bottom: 6px;
}
