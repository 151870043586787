/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  max-width: 650px;
  margin: 16px -10px -13px;

  @media $mobile {
    margin: 25px auto 0;
  }
}

.button {
  display: block;

  width: 100%;
  height: 42px;
  padding: 0 18px;

  color: #fff;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 42px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  background: $brandColor;
  border-width: 0;
  border-radius: 8px;
  outline: none;

  cursor: pointer;

  transition: background-color 0.15s ease-out;

  appearance: none;
  user-select: none;

  @media $landscapeTablet {
    padding: 0 18px;

    font-size: 14px;
    line-height: 42px;
  }
}

.button::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  content: '';
}

.button:hover {
  background-color: #9e784c;

  @media (hover: none) {
    background-color: $brandColor;
  }
}

.image {
  position: relative;

  margin: 0 -10px 10px;

  @media $mobile {
    margin: 0 0 25px;
  }
}

.image::after {
  display: block;

  padding-bottom: 56.9230769231%;

  content: '';
}

.image img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
