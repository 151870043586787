/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;

  gap: 12px;

  max-width: 650px;
  margin: 0 0 10px;

  @media $mobile {
    margin: 0 auto 20px;
  }
}

.list {
  margin: 0;
  padding: 0;

  font-family: $secondaryFont;

  list-style: none;
  background: #fff;

  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.episode {
  padding: 14px 12px 0;

  font-size: 16px;
  line-height: 22px;

  border-bottom: 1px solid #ebebeb;

  @media $mobile {
    padding: 17px 25px 0;

    font-size: 20px;
    line-height: 28px;
  }
}

.episode:first-child {
  border-radius: 8px 8px 0 0;

  @media $mobile {
    padding-top: 20px;
  }
}

.episode:last-child {
  border-width: 0;
  border-radius: 0 0 8px 8px;
}

.episode:only-child {
  border-radius: 8px;
}

.episode:hover {
  background-color: #f9f9f9;
}

.episode h3 {
  margin-bottom: 4px;

  font-weight: 700;

  font-size: inherit;
  line-height: inherit;

  @media $mobile {
    margin-bottom: 8px;
  }
}

.episode > a {
  color: inherit;
}

.episode p {
  margin: 0;
}

.play {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 34px;
  margin-right: 6px;
  margin-left: auto;
}

.player {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 11px 0 16px;

  line-height: 24px;

  cursor: default;

  user-select: none;
}

.download {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  color: #969696;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: currentColor;
}

.mainPanel {
  display: flex;
  gap: 10px;
  align-items: center;
}

.additionalPanel {
  display: flex;
  gap: 10px;
  align-items: center;
}