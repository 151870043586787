/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  margin-bottom: 20px;
}

.root:last-child {
  margin-bottom: 0;
}
