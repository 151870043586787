/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  text-align: center;
}

.menu {
  padding: 18px 0 20px;

  font-size: 16px;
  line-height: 22px;

  border-top: 1px solid #323232;
}

.item {
  padding: 25px 0 24px;

  border-top: 1px solid #323232;
}

.item button,
.menu {
  color: rgba(255, 255, 255, 0.5);
}

.item {
  color: #fff;
}

.userIcon {
  position: relative;

  display: inline-flex;

  margin-top: -4px;

  vertical-align: middle;
}

.label {
  padding-left: 6px;
}

.notify {
  position: absolute;
  top: -3px;
  right: 0;
  z-index: 1;

  width: 7px;
  height: 7px;

  background: #d09959;

  border: 1px solid #000;
  border-radius: 50%;
}

.email::after {
  display: inline-block;

  width: 22px;

  text-align: center;

  content: '·';
}

.login {
  padding: 20px 0;

  border-top: 1px solid #323232;
}

.link,
.login button {

  color: #fff;
  font-weight: 600;

  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  appearance: none;

  @media $mobile {
    font-size: 14px;
    line-height: 17px;
  }
}

.item button,
.login button,
.logout {
  margin: 0;
  padding: 0;

  background-color: transparent;

  border-width: 0;

  appearance: none;
}

.logout {

  color: inherit;
  text-transform: lowercase;
  text-decoration: underline;
}

/* TMP Fix */
.userIcon svg {
  @media $landscapeTabletMax {
    width: 14px;
    height: 14px;
  }
}
