/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  padding: 11px $gapSmall;

  font-weight: normal;

  background-color: #fff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  cursor: pointer;
}
