/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 500px;

  background-color: #fff;

  border-radius: 8px;

  @media $contentWidth {
    align-items: center;
    justify-content: center;

    min-height: 80vh;
    padding-top: 25px;
    padding-bottom: 25px;

    background-color: #f2f2f2;
  }
}

.container {
  position: relative;
  z-index: 1;

  flex-grow: 1;

  padding: 22px 20px 12px;

  @media $mobile {
    flex-grow: 0;
    align-items: center;

    width: 100%;
    max-width: 650px;
    padding: 27px 25px 18px;

    background-color: #fff;

    border-radius: 8px;
  }
}

.play {
  @media $mobile {
    text-align: center;
  }
}

.container iframe {
  display: block;

  width: 100%;

  border: 0;
}

[data-embed] {
  width: 100%;
  overflow: hidden;

  border-radius: 8px;

  transform: translateZ(0);
  visibility: hidden;

  opacity: 0;
}

.ingame {
  padding-top: 0;
  padding-bottom: 0;

  @media $mobile {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.ingame .container > * {
  display: none;
}

.root [data-embed] {
  display: block;
}

.ingame .container {
  padding: 0;
}

.ingame.loaded .container {
  background: transparent;
  box-shadow: none;
}

.ingame.loaded [data-embed],
.ingame:not(.loaded) .spinner {
  display: block;

  visibility: visible;

  opacity: 1;
}

.spinner {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: 10;

  background: #fff;
}

.ingame:not(.loaded) .container {
  height: 500px;
}

.shadow [data-embed] {
  @media $mobile {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}

.shadow iframe {
  border-top: 0;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: none;

  background-color: #000;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;

  border-radius: inherit;

  @media $mobile {
    display: block;
  }
}
