@import '../../../styles/vars.css';

.root {
  min-height: calc(100vh - 100px);
  padding: 30px 10px;

  background-color: #f2f2f2;

  border-radius: 10px;

  @media $mobile {
    padding: 45px 20px;

    border-radius: 8px;
  }
}

.container {
  max-width: 650px;
  margin: 0 auto;
}

.header {
  margin-bottom: 30px;

  text-align: center;

  @media $mobile {
    margin-bottom: 45px;
  }
}

.title {
  margin: 0 0 10px;

  color: #000;
  font-weight: 700;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 24px;

  @media $mobile {
    margin: 0 0 15px;

    font-size: 37px;
    line-height: 42px;
  }
}

.block {
  padding: 10px;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  @media $mobile {
    padding: 25px;

    font-size: 16px;
    line-height: 22px;
  }
}

.button + .button {
  margin-top: 10px;

  @media $mobile {
    margin-top: 15px;
  }
}
