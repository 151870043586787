/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  width: 100%;
  margin: 0;
  padding-right: 0;
  padding-bottom: 1px;
  padding-left: 0;

  font-family: $secondaryFont;

  text-indent: 10px;

  border-width: 0;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  appearance: none;
}

.root[disabled] {
  color: #000;

  background: #f0f0f0;

  user-select: none;
}

.root::placeholder {
  color: #999;

  opacity: 1;
}

.invalid:not(:focus) {
  box-shadow: inset 0 0 0 1px #f95a5a;
}

.medium {
  height: 40px;

  font-size: 16px;
  line-height: 18px;

  @media $mobile {
    height: 42px;

    font-size: 18px;
    line-height: 21px;
  }
}
