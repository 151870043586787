/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.circleWrap {
  width: 100%;
  height: 100%;

  transform: rotate(-90deg);

  stroke-width: 6px;

  fill: transparent;
}

.circle .track {
  stroke: rgba(255, 255, 255, 0.2);
}

.circle .played {
  stroke: #fff;
}

.circle.dark[data-unplayed='true'] {
  opacity: 0;
}

.circle.dark[data-unplayed='0'] {
  opacity: 1;
}

.circle.dark .played {
  stroke: #000;
}

.panel {
  width: 100%;

  border-radius: 4px;
}

.panel .track {
  position: relative;

  width: 100%;
  height: 6px;

  background: rgba(0, 0, 0, 0.1);

  border-radius: 4px;

  cursor: grab;
}

.panel .played {
  height: 100%;
  margin-top: 3px;

  background: #b9bab7;

  border-radius: 4px;
}

.knob {
  position: absolute;
  top: -5px;

  width: 12px;
  height: 12px;
  margin-left: -1px;

  background-color: #000;

  border-radius: 50%;
  box-shadow: 0 0 0 2px #f7f7f7;
  cursor: grab;

  content: '';

  @media $mobile {
    width: 14px;
    height: 14px;
  }
}

.isInEpisodeBlock {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  stroke-width: 4px;
}

.isInEpisodeBlock .circleWrap {
  width: 62px;
  height: 62px;

  border-radius: 50%;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.5);

  stroke-width: 4px;
}

.isInAudioPanel .played {
  background-color: #000;
}

.isInEpisodeBlock .track {
  fill: rgba(0, 0, 0, 0.35);
}

.isInAudioPlayer.panel .track {
  height: 2px;

  background-color: #dadada;
}

.isInAudioPlayer.panel .track::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: 0;

  content: '';
}

.isInAudioPlayer .played {
  background: #b9bab7;
}

.isInAudioPlayer.panel .knob {
  top: -2px;

  width: 6px;
  height: 6px;
  margin-left: -3px;

  background: #b9bab7;
  box-shadow: none;
}

.isInPlaylist[data-unplayed='true'],
.isInPlaylist[data-unplayed='0'],
.isInAudioPlayer.unplayed.circle .track {
  opacity: 0.3;
}

.isInAudioPlayer.circle {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  z-index: -1;
}

.isInAudioPlayer.circle svg {
  stroke-width: 2px;
}

.isInAudioPlayer.circle .track {
  stroke: rgba(255, 255, 255, 0.2);
}

.isInPodcastMaterial {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  z-index: -1;
}

.isInPodcastMaterial .track {
  stroke: #7c7c7c;
}

.isInPlaylist {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 35px;
  height: 35px;

  background: transparent;
}

.isInDynamicBlock {
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  z-index: -1;

  width: 35px;
  height: 35px;

  background: transparent;
}

.isInDynamicBlock svg {
  transform: rotate(-90deg);
}

.isInPlaylist .circleWrap {
  overflow: visible;
}

.isInPlaylist .track {
  stroke: #b3b4b3;
}

.isInPlaylist .played {
  stroke: #000;
}

.isInPlaylist.unplayerd {
  opacity: 0.4;
}

/* THEMES */
.ghost .knob {
  background-color: #fff;
  box-shadow: none;
}

.ghost .played {
  background-color: #fff;
}

.ghost .track {
  background-color: rgba(255, 255, 255, 0.1);
}
