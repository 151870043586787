/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  width: 100%;
}

.root::after {
  flex-shrink: 0;

  width: 15px;

  content: '';
}

.root::before {
  position: absolute;
  top: calc(-45rem / $fontSize);
  right: calc(-45rem / $fontSize);
  bottom: calc(-45rem / $fontSize);
  left: calc(-45rem / $fontSize);
  z-index: -1;

  background-color: #fff;

  border-radius: 10px;

  content: '';
}

.container {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: stretch;
}

.header {
  width: 33.333333333%;
  margin-top: -4px;
  padding: 0 15px;
}

.title {
  font-size: calc(36rem / $fontSize);
  font-style: italic;
  line-height: calc(40rem / $fontSize);
}

.list {
  display: flex;
  flex-flow: row wrap;

  width: 66.66666666%;
  margin: 0;
  margin-left: 15px;
  padding: 10px 0 0;

  list-style: none;
}

.item {
  position: relative;

  display: inline-flex;
  flex-direction: column;

  width: 50%;
  padding: 0 15px 30px;

  border-bottom: 0;
}

.item:hover {
  z-index: $zIndex-1;
}

.item:nth-child(even) {
  padding-right: 0;
  padding-left: 30px;
}

.item:nth-last-of-type(-n + 2) {
  padding-bottom: 0;
}

.footer {
  display: flex;
  justify-content: flex-end;

  margin-bottom: -5px;
  padding: 30px 15px 0;

  font-weight: 400;

  font-size: 19px;
  font-style: italic;
  line-height: 23px;
}

.footer a:hover {
  @media $landscapeTablet {
    opacity: 0.8;
  }
}

.footer a::after {
  display: inline-block;

  width: 15px;
  height: 5px;
  margin-left: 4px;

  vertical-align: middle;

  background-image: url("data:image/svg+xml,%3Csvg width='15' height='5' viewBox='0 0 15 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.901 4.725c.19-.405.374-.76.551-1.064a5.95 5.95 0 0 1 .551-.76H.926v-.798h11.077a7.41 7.41 0 0 1-.551-.779 15.406 15.406 0 0 1-.551-1.045h.665c.798.925 1.634 1.609 2.508 2.052v.342c-.874.43-1.71 1.115-2.508 2.052h-.665Z' fill='%23000'/%3E%3C/svg%3E");

  content: '';
}
