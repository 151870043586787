/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  min-height: calc(100vh - 70px);
  padding: 13px 10px 10px;

  background-color: #f2f2f2;

  border-radius: 10px;

  @media $mobile {
    min-height: calc(100vh - 77px);
    padding: 25px 10px 45px;
  }
}

.wrapper {
  max-width: 825px;
  margin: 0 auto;
}

.title {
  margin-bottom: 12px;

  color: #262626;
  font-weight: 600;
  font-size: 15px;

  font-family: $secondaryFont;
  line-height: 18px;
  letter-spacing: 1px;

  text-align: center;
  text-transform: uppercase;

  @media $mobile {
    margin-bottom: 26px;

    font-size: 26px;
    line-height: 31px;

    letter-spacing: 1.35px;
  }
}

.item {
  position: relative;

  margin: 12px 0;
}

.item:first-child {
  margin-top: 0;
}

.item:last-of-type {
  margin-bottom: 0;
}

.empty {
  margin-top: -4px;

  color: #787878;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  text-align: center;

  @media $mobile {
    margin-top: -10px;

    font-size: 18px;
    line-height: 26px;
  }
}
