.root {
  display: flex;

  gap: 10px;
  align-items: center;
}

.label {
  color: #fff;
  font-size: 16px;

  font-family: inherit;
  line-height: 22px;

  background: none;
  border-width: 0;

  appearance: none;
}
