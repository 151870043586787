/* stylelint-disable declaration-empty-line-before */
@import '../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;

  min-height: 100vh;

  background-color: $bgColor;
}

.header {
  position: relative;
  z-index: $zIndex-1;

  width: 100%;
  max-width: $layoutWidth;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;

  @media $landscapeTablet {
    z-index: auto;

    margin-bottom: 15px;
  }
}

.content {
  position: relative;
  z-index: $zIndex-1;

  flex-grow: 1;

  width: 100%;
  min-height: calc(
    100vh - 140px
  ); /* vh - Header, InfoPanel height and margins */

  @media $mobile {
    max-width: $layoutWidth;
    margin-right: auto;
    margin-left: auto;
  }
}

[data-route='screen'] .content {
  min-height: auto;
}

[data-material='games'] .content {
  min-height: 0;
}

.controls {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: $zIndex-3;

  @media $mobile {
    right: 15px;
    bottom: 15px;
    z-index: $zIndex-3;
  }
}

.banner {
  position: relative;
  z-index: $zIndex-1;

  width: 100%;
  height: 250px;

  overflow: hidden;

  line-height: 0;

  background-color: #000;

  @media $landscapeTablet {
    z-index: $zIndex-1;
  }
}

.footer {
  margin-top: 50px;

  @media $mobile {
    margin-top: 64px;
  }
}

[data-gdpr-panel='true'] .controls {
  @media $mobile {
    margin-bottom: 46px;
  }
}

[data-gdpr-panel='true'] .footer {
  margin-bottom: 44px;

  @media $mobile {
    margin-bottom: 46px;
  }
}

[data-modal='search'] .controls {
  z-index: -1;
}

[data-modal='search'] .content,
[data-modal='search'] .footer {
  display: none;
}

[data-modal='search'] .header {
  z-index: $zIndex-4;

  @media $landscapeTablet {
    height: 78px;
  }
}

.skipLink {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;

  border: 0;

  clip: rect(0 0 0 0);
}

[data-material='brand'] .content {
  max-width: none;
}

[data-pathname='/specials/care'] {
  overflow: hidden;

  background-color: #3b3b3b;
}

[data-nav='true'] .content,
[data-nav='true'] .footer {
  @media $landscapeTabletMax {
    display: none;
  }
}

[data-nav='true'] .controls {
  z-index: $zIndex-3;
}

[data-modal='loginHeader'] .content,
[data-modal='loginHeader'] .footer,
[data-modal='loginBookmark'] .content,
[data-modal='loginBookmark'] .footer,
[data-modal='postAuth'] .content,
[data-modal='postAuth'] .footer,
[data-modal='sendMessage'] .content,
[data-modal='sendMessage'] .footer {
  @media $mobileMax {
    display: none;
  }
}

[data-modal='idea'],
[data-modal='deleteUser'] {
  @media $mobileMax {
    overflow: hidden;
  }
}

[data-pathname='/specials/blog'] {
  background-image: url('https://meduza.io/image/attachments/images/006/179/957/original/FGwwNYZh3_Qqk0fFDgYvNQ.jpg');
  background-repeat: no-repeat;
  background-position: center 250px;
  background-size: 1070px auto;

  @media $mobile {
    background-image: url('https://meduza.io/image/attachments/images/006/179/962/original/5SArT3mvsOsYNGMqUhFLew.jpg');
    background-size: 2240px auto;
  }
}

[data-input='mouse'] button,
[data-input='mouse'] input,
[data-input='mouse'] textarea,
[data-input='mouse'] a {
  outline: none;
}
