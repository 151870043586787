@import '../../../styles/vars.css';

.root {
  position: relative;
  z-index: 10;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  gap: 18px;
  align-items: center;

  padding: 20px 12px 10px;
  overflow-y: auto;

  color: #fff;
  -webkit-overflow-scrolling: touch;

  overscroll-behavior: contain;

  @media (min-width: 360px) {
    padding: 20px 20px 10px;
  }

  @media $landscapeTablet {
    flex-flow: row nowrap;

    gap: 24px;
    align-items: center;
    justify-content: space-between;

    padding: 35px 50px;
  }
}

.main {
  display: flex;
  flex-flow: column nowrap;

  gap: 2px;

  width: 100%;
  max-width: 650px;

  @media $landscapeTablet {
    max-width: 524px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;

  gap: 16px;

  width: 100%;
  max-width: 650px;

  font-size: 16px;

  font-family: $primaryFont;
  line-height: 22px;

  @media (min-width: 360px) {
    gap: 20px;

    font-size: 18px;
    line-height: 24px;
  }

  @media $landscapeTablet {
    max-width: 582px;

    font-size: 20px;
    line-height: 28px;
  }
}

.content p {
  margin: 0;
}

.content i {
  @media $landscapeTabletMax {
    display: block;

    margin-bottom: 20px;
  }

  @media (max-width: 359px) {
    margin-bottom: 10px;
  }
}

.button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  height: 60px;

  color: #000;
  font-weight: 600;

  font-size: 14px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: #fff;
  border-width: 0;
  border-radius: 30px;
}

.icon {
  margin-left: 1px;

  transform: translateY(-4px);
}

.button:hover {
  opacity: 0.8;
}

.header {
  display: flex;
  flex-flow: row nowrap;

  gap: 2px;
}

.variant {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;

  height: 40px;

  background-color: rgba(255, 255, 255, 0.2);

  border-radius: 82px;
}

.variantButton {
  width: 50%;
  height: 40px;
  padding: 2px 0 0;

  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;

  font-size: 8px;
  line-height: 136%;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  background-color: transparent;
  border-width: 0;
  border-radius: 40px;

  cursor: pointer;

  @media (min-width: 360px) {
    padding-top: 3px;

    font-size: 12px;
  }
}

.variantButton.isActive {

  color: #000;

  background-color: #fff;
  cursor: default;
}

.currency {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;

  width: 82px;
  height: 41px;

  background-color: rgba(255, 255, 255, 0.2);

  border-radius: 82px;
}

.currencyButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 41px;
  height: 41px;
  padding-top: 3px;

  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;

  font-size: 12px;
  line-height: 136%;
  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: transparent;
  border-width: 0;
  border-radius: 41px;

  cursor: pointer;
}

.currencyButton.isActive {

  color: #000;

  background-color: #fff;
  cursor: default;
}

.variantsList {
  display: flex;
  flex-flow: column nowrap;

  gap: 2px;

  @media $landscapeTablet {
    flex-flow: row nowrap;
  }
}

.variantsItem {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 1000;
  height: 66px;
  padding: 12px;

  color: #000;

  text-align: center;

  background-color: #fff;
  border-radius: 10px;

  @media $landscapeTablet {
    flex-flow: column nowrap;
    flex-grow: 1;

    width: 33.3333%;
    height: 144px;
  }
}

.variantsItemContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  @media $landscapeTablet {
    flex-flow: column nowrap;
  }
}

.variantsItemText {
  font-weight: 700;
  font-size: 56px;
  font-family: $primaryFont;
  line-height: 68px;
}

.variantsItemDescription {
  color: #000;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  font-style: italic;
  line-height: 136%;
}

.variantsItemButton {
  width: 138px;
  margin-left: auto;

  @media $landscapeTablet {
    width: 100%;
    margin-top: auto;
    margin-left: 0;
  }
}

.variantsItemButton a {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 42px;

  color: #fff;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: #000;
  border-radius: 8px;

  transition: background-color 300ms ease;
}

.variantsItemButton a::after {
  position: absolute;

  content: '';

  inset: 0;
}

.variantsItemButton a:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.variantsItemIcon {
  @media $landscapeTablet {
    margin-bottom: -12px;
    padding-top: 3px;
  }
}

.variantsItemIcon svg {
  display: block;

  width: 48px;
  height: 48px;
}
