@import '../../../../styles/vars.css';

.modalButton {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 650px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  padding: 13px 0;
  overflow: hidden;

  color: #000;
  font-weight: 600;
  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  background: #f0f0f0;
  border-width: 0;
  border-radius: 8px;

  cursor: pointer;

  @media $landscapeTablet {
    margin-bottom: 30px;
  }
}

.modalButtonCard {
  justify-content: start;
  max-width: 630px;
  margin-bottom: 10px;
  padding: 13px 12px;

  background: #f2f2f2;

  border: 1px solid rgba(0, 0, 0, 0.1);

  @media $portraitTablet {
    margin-bottom: 15px;
  }
  
  @media $landscapeTablet {
    justify-content: center;

    background: rgba(255, 255, 255, 1);
  }
}
