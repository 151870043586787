/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  max-width: 650px;
  margin: 16px 0;

  background: #f5f5f5;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media $portraitTablet {
    margin: 25px auto 20px;
  }
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}

.text p {
  margin: 0;
}

.logo {
  margin-bottom: 28px;

  @media $portraitTablet {
    margin-bottom: 42px;
  }
}

.field label {
  position: absolute;
  z-index: -1;

  visibility: hidden;
}

.logo svg {
  display: block;

  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.subscribe {
  position: relative;
}

.message a {
  color: inherit;
  text-decoration: underline;
}

.isSignal {
  padding: 12px 8px 8px;

  @media $portraitTablet {
    height: 343px;
    padding: 22px 20px;
  }
}

.isSignal .content {
  margin-bottom: 38px;

  font-size: 16px;
  line-height: 22px;

  text-align: center;

  @media $portraitTablet {
    margin-bottom: 34px;

    font-size: 20px;
    line-height: 28px;
  }
}

.isSignal .content span {
  color: #b7b7b7;
}

.isSignal .content a {
  color: inherit;

  border-bottom: 1px solid;
}

.isSignal .content a:hover {
  color: $brandColor;
}

.isSignal .form {
  position: relative;
}

.isSignal .field {
  flex-grow: 1;

  margin-bottom: 8px;

  @media $portraitTablet {
    margin-bottom: 0;
  }
}

.button {

  border-width: 0;
  cursor: pointer;

  appearance: none;
}

.isSignal .button {
  width: 100%;
  height: 52px;

  color: #fff;

  background: #000;
  border-radius: 7px;

  @media $portraitTablet {
    position: absolute;
    top: 0;
    right: 0;

    width: 185px;
    height: 62px;
  }
}

.isSignal .button:disabled {
  color: rgba(255, 255, 255, 0.3);

  cursor: default;
}

.isSignal .button:disabled:hover {
  opacity: 1;
}

.isSignal .button:hover {
  opacity: 0.8;
}

.isSignal .button:active {
  opacity: 1;
}

.isSignal input {
  width: 100%;
  height: 52px;

  font-size: 19px;

  text-align: center;

  background-color: transparent;
  background-color: #fff;

  border-width: 0;
  border-radius: 7px;
  outline-width: 0;
  box-shadow: inset 0 0 0 1px #cecece;

  @media $portraitTablet {
    height: 62px;
    padding-right: 200px;

    text-align: left;
    text-indent: 15px;
  }
}

.isSignal input:focus {
  box-shadow: inset 0 0 0 1px #000;
}

.isSignal input::placeholder {
  color: #000;
}

.isSignal .invalid input {
  box-shadow: inset 0 0 0 1px #ff8a00;
}

.isSignal .message {
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;

  padding-top: 3px;
  padding-bottom: 3px;

  color: #ff8a00;
  font-size: 12px;

  line-height: 13px;

  text-align: center;

  @media $landscapeTablet {
    padding-bottom: 0;

    line-height: 28px;
  }
}

.isSignal.isSuccess .form {
  visibility: hidden;
}

.isSignal .success {
  position: absolute;
  top: 0;

  font-size: 16px;
  line-height: 22px;

  text-align: center;

  @media $portraitTablet {
    top: auto;
    bottom: 0;

    font-size: 20px;
    line-height: 28px;
  }
}

.isDaily {
  min-height: 301px;
  padding: 12px;

  @media $portraitTablet {
    min-height: 279px;
    padding: 24px 20px 12px;
  }
}

.isDaily .content {
  margin-bottom: 20px;
}

.isDaily .title {
  margin: 0 0 10px;

  font-weight: 700;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 24px;

  @media $portraitTablet {
    margin-bottom: 15px;

    font-size: 28px;
    line-height: 34px;
  }
}

.isDaily .text {
  font-size: 16px;
  font-family: $secondaryFont;
  line-height: 22px;

  @media $portraitTablet {
    font-size: 18px;
    line-height: 26px;
  }
}

.isDaily .text p + p {
  margin-top: 12px;
}

.isDaily .button {
  width: 100%;
  height: 42px;

  color: #fff;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;

  background: #b78b5c;
  border-radius: 8px;

  @media $portraitTablet {
    font-size: 14px;
  }
}

.isDaily .button:disabled {

  opacity: 0.4;

  pointer-events: none;
}

.isDaily .field {
  margin-bottom: 10px;
}

.isDaily .field input {
  width: 100%;
  height: 42px;

  font-size: 16px;

  font-family: $secondaryFont;

  text-align: center;

  border: 1px solid #e0e0e0;
  border-radius: 8px;

  @media $portraitTablet {
    font-size: 18px;
  }
}

.isDaily .message,
.isDaily .description {
  padding-top: 10px;

  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 16px;

  text-align: center;
}

.isDaily .message {
  display: block;

  color: #ea4335;
}

.isDaily .description {
  color: rgba(0, 0, 0, 0.4);
}

.isDaily .description p {
  margin: 0;
}

.isDaily .description a {
  color: inherit;

  box-shadow: inset 0 -1px currentColor;
}

.isDaily .description a:hover {
  color: $brandColor;
}

.isDaily.isSuccess .subscribe {
  display: none;
}
