@import '../../../styles/vars.css';

.root {
  color: #e0e0e0;
}

.control {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  color: inherit;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: transparent;

  @media $landscapeTablet {
    width: auto;
    height: auto;
    padding: 0;
  }
}

.dark {
  color: #fff;

  opacity: 0.4;
}

.light {
  color: #000;

  opacity: 0.25;
}

.isInBookmarks .control {
  fill: #e0e0e0;
}

.isInBookmarks.dark .control {
  fill: #fff;
}

.isInBookmarks.light .control {
  fill: #000;
}

.isInGrid .control svg {
  @media $landscapeTablet {
    width: 22px;
  }
}

.isInEpisode {
  opacity: 0.29;
}

.isInAudioPanel {
  color: #000;

  opacity: 0.25;
}
