/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: fixed;
  bottom: -1px;
  left: 0;
  z-index: 400;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1336px;
  height: 50px;
  padding: 0 9px 0 $gapSmall;

  color: #fff;

  background: #000;
  border-radius: 8px 8px 0 0;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);

  @media $mobile {
    left: 50%;

    height: 46px;
    padding: 0 18px 1px $gapMedium;

    transform: translateX(-50%);
  }
}

.text {
  max-width: 196px;
  padding-bottom: 1px;

  font-weight: 600;
  font-size: 10px;

  font-family: $secondaryFont;
  line-height: 12px;

  letter-spacing: 1px;
  text-transform: uppercase;

  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

  @media $mobile {
    max-width: none;
    padding-top: 2px;
    padding-bottom: 0;

    font-size: 14px;
    line-height: 16px;
  }
}

.text a {
  color: #fff;
  white-space: nowrap;

  box-shadow: inset 0 -1px $brandColor;
}

.text a:hover {
  color: $brandColor;

  border-color: transparent;
}

.dismiss {
  margin: 0 11px 0 0;
  padding: 0;

  color: #fff;

  background-color: transparent;
  border: 0;
  border-radius: 0;

  cursor: pointer;

  appearance: none;

  @media $portraitTablet {
    margin-right: 0;
  }
}

.dismiss svg {
  display: block;
}

.dismiss:hover {
  fill: rgba(255, 255, 255, 0.7);
}
