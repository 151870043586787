/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */

@import '../../../../styles/vars.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-flow: column nowrap;

  @media $landscapeTablet {
    flex-flow: row wrap;

    margin: -15px 0;

    background: repeating-linear-gradient(to bottom,
    transparent,
    transparent 98px,
    #d8d8d8 98px,
    #d8d8d8 99px);
  }
}

.block {
  @media $landscapeTablet {
    width: 25%;
    padding: 15px 0;
  }
}

.block::after {
  display: block;

  height: 1px;
  margin: 5px -10px 5px 70px;

  background-color: #e0e0e0;

  content: '';

  @media $landscapeTablet {
    content: none;
  }
}

.block:last-child::after {
  content: none;
}

.item {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.item::before {
  position: absolute;
  top: 50%;
  right: 0;

  width: 12px;
  height: 21px;

  background-image: url("data:image/svg+xml,%3Csvg width='12' height='21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 19.5l9-9-9-9' stroke='%23E0E0E0' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  transform: translateY(-50%);

  content: '';

  @media $landscapeTablet {
    content: none;
  }
}

.item:hover {
  opacity: 0.85;
}

.image {
  position: relative;

  display: block;
  flex-shrink: 0;

  width: 58px;
  height: 58px;
  margin-right: 12px;

  background: #d8d8d8;

  border-radius: 2px;
  box-shadow: 0 20px 109px rgba(0, 0, 0, 0.06),
    0 10px 47px rgba(0, 0, 0, 0.0405), 0 4px 17px rgba(0, 0, 0, 0.03),
    0 1px 6px rgba(0, 0, 0, 0.0195);

  @media $landscapeTablet {
    width: 68px;
    height: 68px;
  }
}

.image::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  content: '';
}

.image img {
  display: block;

  width: 100%;
  height: 100%;

  border-radius: 2px;
}

.body {
  padding-bottom: 2px;

  @media $landscapeTablet {
    padding-top: 1px;
    padding-bottom: 0;
  }
}

.title {
  margin: 0 0 1px;

  font-weight: 700;
  font-size: 17px;

  font-family: $secondaryFont;
  line-height: 21px;

  @media $landscapeTablet {
    margin: 0 0 2px;

    font-size: 18px;
    line-height: 23px;
  }
}

.meta {
  color: #757575;
}

.meta svg {
  display: none;
}
