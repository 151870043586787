/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  display: block;

  @media $landscapeTablet {
    display: none;
  }
}

.ad {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zIndex-3;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 100%;
  padding: 11px 20px 10px;

  background: #f0f0f0;
  border-radius: 0 0 8px 8px;
  transform: translateY(-100%);
  visibility: hidden;

  cursor: pointer;

  opacity: 0;

  transition: transform 250ms ease, visibility 250ms ease, opacity 250ms ease;

  -webkit-tap-highlight-color: transparent;
}

.link {
  display: block;
  flex-grow: 1;

  min-width: 0;
  margin-right: 20px;

  color: #464647;
  font-weight: 600;
  font-size: 10px;

  font-family: $secondaryFont;
  line-height: 12px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.visible .ad {
  transform: translateY(0);
  visibility: visible;

  opacity: 1;
}

.title {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.label {
  display: block;

  color: #b78b5c;
}

.button {
  flex-shrink: 0;

  margin: 0;
  padding: 0;

  color: #c2c2c2;
  vertical-align: top;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
}

.button:active {
  color: #000;
}

.button svg {
  display: block;
}

[data-gdpr-panel='true'] .root,
[data-gdpr-panel='true'] .ad {
  display: none;
}
