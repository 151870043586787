/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;

  @media $landscapeTablet {
    padding: 0 15px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;

  width: 100%;
  max-width: 335px;
  /* Чтобы избежать перескакивание формы вверх вниз */
  min-height: 268px;

  color: #fff;

  @media $landscapeTablet {
    align-items: start;

    max-width: none;
    /* Чтобы избежать перескакивание формы вверх вниз */
    min-height: 237px;
  }
}

.content a {
  color: inherit;
}

.content a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.tag {
  margin-bottom: 14px;

  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;

  font-size: 12px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.image {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 14px;
  padding-top: 2px;

  @media $landscapeTablet {
    align-items: flex-start;
  }
}

.imageBeet {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* Отступы и размеры для правильного размещения картинки THE BEET*/
  max-width: 203px;
  height: 60px;
  height: 52px;
  margin-bottom: 14px;
  margin-bottom: 8px;
  padding-top: 2px;

  @media $landscapeTablet {
    align-items: flex-start;
    /* Отступ снизу для текста под картинкой */

    margin-bottom: 7px;
  }
}

.image svg {
  max-width: 308px;
  height: auto;
}

.title {
  margin-top: -4px;
  margin-bottom: 8px;

  font-weight: 700;

  font-size: 30px;
  line-height: 1;

  @media $landscapeTablet {
    font-size: 32px;
  }
}

.textRu {
  font-size: 14px;
  font-family: $secondaryFont;
  line-height: 18px;
}

.textEn {
  margin-bottom: 20px;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  @media $landscapeTablet {
    margin-bottom: 11px;
  }
}

.textRu br, .textEn br {
  @media $landscapeTabletMax {
    display: none;
  }
}

.textRu p, .textEn p {
  max-width: 930px;
  margin: 0;
}

.textRu p + p, .textEn p + p {
  margin-top: 14px;
}

.textRu span, .textEn span {
  color: rgba(255, 255, 255, 0.4);
}


.success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  padding: 0 13px;

  color: #fff;

  font-size: 14px;
  line-height: 18px;

  text-align: center;

  background-color: #191919;
  border-radius: 8px;
}

.success p {
  margin: 0;
}

.success strong {
  font-weight: normal;
}

.sosLogo {
  display: flex;
  gap: 15px;
}