/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  height: 42px;
  padding: 14px $gapSmall 10px;

  background-color: #fff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  cursor: pointer;
}
