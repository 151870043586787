/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */

@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  color: #000;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e5e5e5;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;
}

.root:hover {
  @media $landscapeTablet {
    opacity: 0.95;
  }
}

.root::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;

  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;

  content: '';
  pointer-events: none;
}

.bookmark {
  position: absolute;
  right: 10px;
  bottom: 14px;
  z-index: $zIndex-1;

  opacity: 0.4;

  @media $landscapeTablet {
    right: 14px;
    bottom: 10px;
  
    opacity: 0.2;
  }
}
