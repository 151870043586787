/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.control {
  position: relative;
  z-index: $zIndex-1;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.button {
  width: 42px;
  height: 42px;

  background: #b88b58;

  border-width: 0;
  border-radius: 50%;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24),
    inset 0 0 0 1px rgba(255, 255, 255, 0.05);

  appearance: none;

  @media $mobile {
    width: 45px;
    height: 45px;
  }
}
