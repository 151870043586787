/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  max-width: 510px;
  margin: 0 auto;

  @media $landscapeTablet {
    display: none;
  }
}

.container {
  padding: 10px;

  background-color: #f7f7f7;

  border-radius: 10px;
}

.container:empty {
  display: none;
}

.container + .container {
  margin-top: 10px;
}

.header {
  position: sticky;
  top: 0;
  z-index: $zIndex-2;

  margin: -12px -10px 0;
  padding-top: 2px;

  text-align: center;

  background-color: #000;
}

.header h2 {
  margin: 0;
  padding: 13px 21px 10px;

  font-weight: 600;
  font-size: 15px;

  font-family: $secondaryFont;
  line-height: 18px;

  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: #f7f7f7;

  border: 1px solid #f7f7f7;
  border-radius: 10px 10px 0 0;
}

.block {
  margin-bottom: 10px;
}

.block:last-child {
  margin-bottom: 0;
}

.group {
  margin-bottom: 10px;

  background-color: #fff;

  border-radius: 8px;
}

.group:empty {
  margin: 0;
}

.group:last-child {
  margin-bottom: 0;
}

.groupItem {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1px 1px 0;
}

.groupItem:first-child {
  border-radius: 8px 8px 0 0;
}

.groupItem:last-child {
  border-width: 1px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.starred {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: inset 0 0 0 3px #b78a54;
}

[data-pathname='/specials/care'] .header {
  background-color: #3b3b3b;
}

.footer {
  display: flex;
  justify-content: flex-end;

  padding: 5px 14px 8px;

  font-weight: 400;

  font-size: 19px;
  font-style: italic;
  line-height: 23px;
}

.footer a:hover {
  @media $landscapeTablet {
    opacity: 0.8;
  }
}

.footer a::after {
  display: inline-block;

  width: 15px;
  height: 5px;
  margin-left: 4px;

  vertical-align: middle;

  background-image: url("data:image/svg+xml,%3Csvg width='15' height='5' viewBox='0 0 15 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.901 4.725c.19-.405.374-.76.551-1.064a5.95 5.95 0 0 1 .551-.76H.926v-.798h11.077a7.41 7.41 0 0 1-.551-.779 15.406 15.406 0 0 1-.551-1.045h.665c.798.925 1.634 1.609 2.508 2.052v.342c-.874.43-1.71 1.115-2.508 2.052h-.665Z' fill='%23000'/%3E%3C/svg%3E");

  content: '';
}
