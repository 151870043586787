/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;

  background: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.is1to1 {
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: stretch;

  padding-top: 13px;
}

.featured {
  position: relative;

  display: inline-flex;

  width: 100%;

  border-bottom: 1px solid #d9d9d9;

  @media $mobile {
    padding: 15px;
  }
}

.is1to1 .featured {
  @media $landscapeTablet {
    width: 50%;
    padding: 9px 0 15px 15px;

    border-bottom: 0;
  }
}

.list {
  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
}

.is1to1 .list {
  @media $landscapeTablet {
    display: flex;
    flex-flow: row wrap;

    width: 50%;
  }
}

.item {
  position: relative;

  width: 100%;

  border-bottom: 1px solid #d9d9d9;

  @media $mobile {
    display: inline-flex;
    flex-direction: column;

    padding: 15px;
  }
}

.item:hover {
  z-index: $zIndex-1;
}

.item:last-child {
  border-bottom: 0;
}

.is1to1 .item {
  @media $landscapeTablet {
    width: 50%;

    border-bottom: 0;
  }
}

.is1to2 .list {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
}

.is1to2 .item {
  flex-grow: 1;
}

.is1to1.hasTag .list {
  padding-top: 17px;
}

.is1to1.hasTag .featured {
  padding-top: 2px;
}

.is1to1 .featured {
  @media $landscapeTablet {
    margin-right: 15px;
  }
}

.is1to1 .item:nth-child(2),
.is1to1 .item:nth-child(4) {
  @media $landscapeTablet {
    padding-left: 23px;
  }
}
