@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  width: 100%;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  transition: opacity 0.2s;
}

.root:hover {
  @media $landscapeTablet {
    z-index: 10;

    opacity: 0.95;
  }
}

.container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  border-radius: inherit;
}

.imageWrap {
  position: relative;

  width: 100%;

  border-radius: 8px 8px 0 0;

  @media $landscapeTablet {
    min-height: 0;
  }
}

.is1to1 .container,
.is1to2 .container {
  background-color: var(--bgColor);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.is1to3 .imageWrap,
.is1to4 .imageWrap {
  background-color: var(--bgColor);
}

.image {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 350ms ease;
}

.picture {
  position: static;

  width: auto;
  height: 160px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1),
    0 15px 15px 0 rgba(0, 0, 0, 0.09), 0 34px 20px 0 rgba(0, 0, 0, 0.05),
    0 60px 24px 0 rgba(0, 0, 0, 0.01), 0 94px 26px 0 rgba(0, 0, 0, 0);

  @media $landscapeTablet {
    height: calc(200rem / $fontSize);
  }
}

.picture img {
  display: block;

  width: auto;
  height: 100%;

  object-fit: contain;
}

.tag {
  display: flex;

  margin-bottom: 6px;

  color: $brandColor;
}

.isLight.is1to1 .tag,
.isLight.is1to2 .tag {
  color: rgba(255, 255, 255, 0.5);
}

.isDark.is1to1 .tag,
.isDark.is1to2 .tag {
  color: rgba(0, 0, 0, 0.6);
}

.contentWrap {
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  padding: 0 15px;

  @media $landscapeTablet {
    padding: 0 calc(15rem / $fontSize);
  }
}

.is1to1 .container {
  @media $landscapeTablet {
    align-items: center;
    justify-content: center;

    padding-bottom: 30px;
  }
}

.is1to1 .contentWrap {
  @media $landscapeTablet {
    flex-grow: 0;
    align-items: center;

    width: calc(680rem / $fontSize);
    padding: 0;
  }
}

.is1to1 .content {
  @media $landscapeTablet {
    align-items: center;

    text-align: center;
  }
}

.is1to2 .contentWrap {
  @media $landscapeTablet {
    justify-content: flex-end;
  }
}

.isLight.is1to1 .footer,
.isLight.is1to2 .footer {
  color: rgba(255, 255, 255, 0.5);

  fill: currentColor;
}

.isDark.is1to1 .footer,
.isDark.is1to2 .footer {
  color: rgba(0, 0, 0, 0.5);

  fill: currentColor;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: $gapSmall 0;

  @media $landscapeTablet {
    justify-content: flex-end;

    padding: 15px 0;
  }
}

.title {
  max-width: 770px;
  margin-bottom: 12px;

  @media $mobile {
    width: 100%;
    min-width: 0;
    margin-bottom: 15px;
  }
}

.isLight.is1to1 .title,
.isLight.is1to2 .title {
  color: #fff;
}

.footer {
  margin-top: auto;

  color: #757575;

  line-height: 14px;

  fill: currentColor;
}

.is1to1 {
  @media $landscapeTablet {
    min-height: 410px;
  }
}

.is1to1 .content {
  @media $landscapeTablet {
    max-width: 585px;
    padding: 0;
  }
}

.is1to1 .imageWrap {
  @media $landscapeTablet {
    position: static;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    padding: 30px 0;
  }
}

.is1to2 .imageWrap {
  padding-top: 50px;
  padding-bottom: 50px;

  @media $landscapeTablet {
    position: static;

    flex-shrink: 0;

    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.is1to2 .content {
  flex-shrink: 0;

  padding-top: 0;

  @media $landscapeTablet {
    flex-grow: 0;
    justify-content: flex-end;
  }
}

.is1to3 .imageWrap,
.is1to4 .imageWrap {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.is1to3 .imageWrap::before,
.is1to4 .imageWrap::before {
  display: block;

  width: 100%;
  padding-bottom: 66.66%;

  content: '';
}

.is1to3 .image,
.is1to4 .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.is1to4 .picture {
  height: calc(140rem / $fontSize);
}
