/* stylelint-disable declaration-empty-line-before */

@import '../../../../styles/vars.css';

.root {
  font-size: 20px;
  line-height: 23px;

  @media $landscapeTablet {
    font-size: calc(19rem / $fontSize);
    line-height: 1.15rem;
  }
}

.first {
  font-weight: 700;
  font-size: 19px;
  font-family: $secondaryFont;

  @media $landscapeTablet {
    font-size: calc(18rem / $fontSize);
  }
}

.second {
  font-weight: normal;
  font-size: inherit;
  font-family: $primaryFont;
}

.isInRich.is1to1 {
  @media $landscapeTablet {
    font-size: calc(27rem / $fontSize);
    line-height: calc(32rem / $fontSize);
  }
}

.isInRich.is1to1 .first {
  @media $landscapeTablet {
    font-size: calc(76rem / $fontSize);
    line-height: calc(72rem / $fontSize);
  }
}

.isInRich.is1to1 .second {
  @media $landscapeTablet {
    display: block;

    width: calc(740rem / $fontSize);
    margin: 14px auto 0;

    font-size: calc(27rem / $fontSize);
    line-height: calc(32rem / $fontSize);
  }
}

.isInEpisode.is1to1 {
  @media $landscapeTablet {
    font-size: calc(27rem / $fontSize);
    line-height: calc(32rem / $fontSize);
  }
}

.isInEpisode.is1to1 .first {
  @media $landscapeTablet {
    font-size: calc(26rem / $fontSize);
    line-height: calc(32rem / $fontSize);
  }
}

.isInSimple .first {
  font-weight: normal;
  font-size: inherit;
  font-family: $primaryFont;
}

.isInBrief.is1to1,
.isInSimple.is1to1 {
  @media $landscapeTablet {
    font-size: calc(50rem / $fontSize);
    line-height: calc(50rem / $fontSize);
  }
}

.isInSimple.is1to1 .first {
  font-size: inherit;
}

.isInSimple.is1to1 .isFeatured {
  font-size: calc(41rem / $fontSize);
}

.isInBrief.is1to1 .first {
  @media $landscapeTablet {
    font-size: calc(41rem / $fontSize);
    line-height: calc(44rem / $fontSize);
  }
}

.is1to1 {
  @media $landscapeTablet {
    font-size: calc(31rem / $fontSize);
    line-height: calc(31rem / $fontSize);
  }
}

.is1to1 .first {
  @media $landscapeTablet {
    font-size: calc(35rem / $fontSize);
    line-height: calc(40rem / $fontSize);
  }
}

.is1to2 {
  @media $landscapeTablet {
    font-size: calc(27rem / $fontSize);
    line-height: calc(32rem / $fontSize);
  }
}

.is1to3.isInSimple.isInFeatured {
  @media $landscapeTablet {
    font-size: calc(27rem / $fontSize);
    line-height: calc(29rem / $fontSize);
  }
}

.is1to3 .first {
  @media $landscapeTablet {
    font-size: calc(20rem / $fontSize);
  }
}

.is1to3.isInSimple.isInFeatured .first,
.isInSimple.is1to2 .isFeatured,
.is1to2 .first {
  @media $landscapeTablet {
    font-size: calc(26rem / $fontSize);
  }
}

.is1to4 {
  @media $landscapeTablet {
    line-height: calc(23rem / $fontSize);
  }
}

.isInEpisode.is1to4 .first,
.isInBrief.is1to4 .first,
.isInRich.is1to4 .first {
  @media $landscapeTablet {
    font-size: calc(18rem / $fontSize);
  }
}

.isInTopicBlockItemFeatured {
  @media $landscapeTablet {
    font-size: calc(37rem / $fontSize);
    line-height: calc(40rem / $fontSize);
  }
}

.isInTopicBlockItemFeatured .first {
  @media $landscapeTablet {
    font-size: calc(36rem / $fontSize);
    line-height: inherit;
  }
}

.isInTopicBlockItemFeatured .second {
  display: inline;
  margin: 0;

  font-size: inherit;
}

.is1to3 {
  @media $landscapeTablet {
    font-size: calc(21rem / $fontSize);
    line-height: calc(26rem / $fontSize);
  }
}

.is1to4.isInSimple {
  @media $landscapeTablet {
    font-size: calc(19rem / $fontSize);
    line-height: calc(23rem / $fontSize);
  }
}

.isInTopicBlockItem,
.isInTopicBlockItemFeatured {
  display: inline;
}
