/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 12px 2px 18px;

  background-color: #000;

  border-radius: 8px;

  @media $landscapeTablet {
    padding: 15px 2px 2px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;

  width: 100%;

  color: #fff;

  text-align: center;
}

.image {
  width: 100%;
  margin-bottom: auto;
  padding: 0 15px;
}

.image svg {
  width: 100%;
  height: auto;
}

.text {
  padding: 0 16px;

  font-size: 18px;

  font-family: $primaryFont;
  line-height: 24px;

  @media $landscapeTablet {
    font-size: 21px;
    line-height: 30px;
  }
}

.text p {
  max-width: 930px;
  margin: 0 auto;
}

.text span {
  color: rgba(255, 255, 255, 0.4);
}

.content a {
  color: inherit;

  box-shadow: inset 0 -1px 0 currentColor;
}

.content a:hover {
  color: $brandColor;
}

.form {
  position: relative;

  width: 100%;
}

.field {
  width: 100%;
  margin-bottom: 2px;
}

.field label {
  position: absolute;
  z-index: 1;

  visibility: hidden;

  opacity: 0;
}

.messageContainer {
  margin: 10px 10px 20px;
}

.message {
  right: 0;
  bottom: 100%;
  left: 0;

  color: #f2614e;
  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 16px;

  text-align: center;

  transform: translateY(-5px);

  @media $landscapeTablet {

    transform: translateY(2px);
  }
}

.input {
  display: block;

  width: 100%;
  height: 64px;
  padding: 0 15px;

  color: #000;
  font-weight: 500;
  font-size: 21px;

  font-family: $primaryFont;
  line-height: 42px;
  text-align: center;

  border-width: 0;
  border-radius: 7px;
  outline: none;

  transition: border-color 0.1s ease-in;

  appearance: none;
}

.input:focus {
  box-shadow: inset 0 0 0 5px #5b8ef7;
}

.input::placeholder {
  color: #000;

  opacity: 1;
}

.button {
  position: relative;
  z-index: 1;

  width: 100%;
  height: 64px;
  padding: 1px 1px 0 0;

  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 28px;
  text-transform: uppercase;

  border-width: 0;
  border-radius: 50px;
  box-shadow: none;

  cursor: pointer;

  appearance: none;

  @media $landscapeTablet {
    font-size: 21px;
    line-height: 42px;
  }
}

.button span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  border-radius: inherit;
}

.button:disabled {

  color: rgba(0, 0, 0, 0.2);

  pointer-events: none;
}

.button:hover span,
.button:active span {
  opacity: 0.7;
}

.success {

  position: absolute;

  top: 50%;

  width: 100%;

  padding: 0 13px;

  color: #fff;

  font-size: 18px;
  line-height: 21px;

  transform: translateY(-50%);

  @media $landscapeTablet {
    font-size: 21px;
    line-height: 30px;
  }
}

.subscribe {
  position: relative;

  width: 100%;
  margin: 21px auto 0;

  @media $landscapeTablet {
    margin-top: 30px;
  }
}

.invalid .input {
  box-shadow: inset 0 0 0 5px #ff7051;
}

.isSuccess .form {
  visibility: hidden;
}

/* Sizes */
.is1to1 {
  padding-top: 26px;
}

.is1to1 .input,
.is1to1 .button {
  @media $landscapeTablet {
    height: 96px;
  }
}

.is1to1 .input:focus {
  box-shadow: inset 0 0 0 10px #5b8ef7;
}

.is1to1 .invalid .input {
  box-shadow: inset 0 0 0 10px #ff7051;
}

.is1to1 .body {
  @media $landscapeTablet {
    width: 100%;
  }
}

.is1to1 .image {
  @media $landscapeTablet {
    margin-bottom: 21px;
    padding: 0 42px;
  }
}

.is1to1 .content {
  @media $landscapeTablet {
    position: relative;

    font-size: 20px;
    line-height: 25px;
  }
}

.is1to2 .input,
.is1to2 .button {
  @media $landscapeTablet {
    height: 74px;
  }
}

.is1to2 .form {
  @media $landscapeTablet {
    display: flex;
    flex-flow: row nowrap;
  }
}

.is1to2 .input {
  @media $landscapeTablet {
    text-align: left;
  }
}

.is1to2 .button {
  @media $landscapeTablet {
    flex-shrink: 0;

    width: 227px;
    margin-left: 2px;
  }
}

.is1to2 .field {
  @media $landscapeTablet {
    margin-bottom: 0;
  }
}

.is1to2 .text,
.is1to3 .text,
.is1to2 .success,
.is1to3 .success {
  @media $landscapeTablet {
    font-size: 19px;
    line-height: 24px;
  }
}

.is1to3 .input,
.is1to3 .button {
  @media $landscapeTablet {
    height: 64px;
  }
}

.is1to3 .text {
  order: 1;

  margin-top: 13px;

  @media $landscapeTablet {
    order: unset;
  }
}

.is1to4 .input,
.is1to4 .button {
  @media $landscapeTablet {
    height: 54px;

    font-size: 19px;
  }
}

.is1to4 .text,
.is1to4 .success {
  @media $landscapeTablet {
    font-size: 17px;
    line-height: 20px;
  }
}
