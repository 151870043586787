/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
}

.control {
  position: relative;
  z-index: $zIndex-1;

  display: block;

  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;

  background-color: transparent;

  border-width: 0;
  border-radius: 2px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.1);

  cursor: pointer;

  appearance: none;
}

.control::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background-color: rgba(0, 0, 0, 0.4);

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  content: '';
}

.control img {
  display: block;

  max-width: 100%;

  border-radius: 2px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.15);
}

.animation {
  position: absolute;
  top: 0;
  z-index: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 60px;
  height: 60px;
  margin: auto;
  padding: 0 16px;
}

@keyframes scale-up-center {
  0% {
    height: 4px;
  }

  50% {
    height: 20px;
  }

  100% {
    height: 4px;
  }
}

.line {
  width: 2px;
  height: 20px;

  background-color: #fff;

  border-radius: 4px;

  animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both
    infinite;
}

.line:nth-child(1) {
  animation-delay: 0.2s;
}

.line:nth-child(2) {
  animation-delay: 0.6s;
}

.line:nth-child(3) {
  animation-delay: 0.1s;
}

.line:nth-child(4) {
  animation-delay: 0.7s;
}

.line:nth-child(5) {
  animation-delay: 0.5s;
}

.player {
  position: relative;
  z-index: $zIndex-2;

  max-height: calc(100vh - 108px);

  padding: 46px 20px 0;
  overflow-y: auto;

  color: #000;
  text-align: center;

  background: #fff;

  border-radius: 8px 8px 0 0;
  transform: translateY(100%);

  transition: transform 350ms ease;

  pointer-events: none;

  @media $contentWidth {
    width: 400px;
    margin-right: 15px;
    margin-left: auto;
    padding: 22px 20px 0;
  }
}

.isVisible.container {

  background-color: rgba(0, 0, 0, 0.6);
  visibility: visible;

  pointer-events: initial;
}

.isVisible .player {
  transform: translateY(0);

  pointer-events: initial;
}

.footer {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  padding-top: 20px;

  padding-bottom: 40px;

  color: #757575;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);

  @media $contentWidth {
    gap: 12px;
    padding-top: 12px;
    padding-bottom: 15px;
  }
}

.footer a {

  color: inherit;
  text-decoration: underline;
}

.button, .closeButton {
  padding: 0;

  color: #757575;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;
  text-decoration: underline;

  background-color: transparent;
  border-width: 0;
  border-radius: 0;

  cursor: pointer;

  appearance: none;
}

.image {
  width: 110px;
  height: 110px;
  margin: 0 auto 20px;
}

.image img {
  display: block;

  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 2px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.15);
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-1;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  visibility: hidden;

  transition: visibility 350ms ease;

  pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.buttonContainer {
  position: sticky;
  top: 15px;
}

.dismissButton {
  position: absolute;

  top: -46px;
  right: -9px;
  left: auto;
  z-index: 10;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;

  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none' style='&%2310; opacity: 0.25;&%2310;'%3E %3Cpath d='M1 1L11 11' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3Cpath d='M11 1L1 11' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  border-width: 0;
  border-radius: 13px;
  box-shadow: none;
  transform: none;

  cursor: pointer;

  opacity: 1;

  appearance: none;

  @media $contentWidth {
    top: -22px;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  max-width: 490px;
  margin: 0 auto;
}

/* обнуляющие стили для кнопки */
.footer button {
  color: inherit;
  font: inherit;
  text-decoration: underline;

  background-color: transparent;
  border: unset;
  cursor: pointer;
}

.footer .closeButton {
  color: #F00;
}
