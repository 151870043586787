/* stylelint-disable declaration-empty-line-before */

@import '../../../../../styles/vars.css';

.root {
  position: relative;

  display: block;

  margin: 0;
}

.root picture {
  position: relative;
  z-index: -1;

  display: block;
}

.root img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  width: 100%;
  max-width: 100%;
  height: auto;
}

.default {
  padding: 0 12px;
}

.default picture {
  overflow: hidden;

  border-radius: 2px;
}

.default img {
  margin: 0 auto;
}

.full {
  position: relative;
}

.rich {
  border-radius: 9px 9px 0 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.rich.default {
  padding-right: 0;
  padding-left: 0;
}

.full::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.15), inset 0 1px rgba(0, 0, 0, 0.15);

  content: '';
}
