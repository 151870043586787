@import '3dparty.css';
@import 'normalize.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  font-weight: normal;
}

* {
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
}
