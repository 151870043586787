/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  width: 100%;
  margin: 0 auto;

  color: #fff;

  background-color: #000;
}

.message {
  padding: 27px 25px 26px;

  color: #757575;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 22px;

  text-align: center;

  background-color: #f2f2f2;
  border-radius: 10px;
}

.message strong {
  display: block;

  margin-bottom: 6px;

  color: #262626;
  font-weight: 600;

  font-size: 26px;
  font-style: normal;
  line-height: 31px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.header {
  padding-top: 7px;

  background-color: #000;
}

.search {
  position: relative;

  max-width: 1335px;
  margin: 0 auto 16px;

  @media $landscapeTablet {

    margin-bottom: 32px;
    overflow: hidden;
  }
}

[data-modal='search'] .search {
  animation: showModal 300ms ease 100ms both;
}

.field {
  width: 100%;
  height: 40px;
  padding: 0 48px 3px 12px;

  color: #fff;
  font-weight: 700;
  font-size: 16px;

  font-family: $secondaryFont;

  background: #323232;
  border-width: 0;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  
  @media $mobile {
    height: 45px;
    padding-right: 24px;
    padding-bottom: 5px;
    padding-left: 0;

    font-size: 26px;

    text-align: center;
    text-indent: 31px;

    border-radius: 10px;
  }
}

.control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 48px;
  margin: 0;
  padding: 0 13px;

  background-color: transparent;

  border-width: 0;
  border-radius: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: #999;

  @media $mobile {
    top: 5px;

    width: 22px;
    padding: 0;
  }
}

.result {
  max-width: 1335px;
  margin-right: auto;
  margin-left: auto;

  @media $mobile {
    margin-top: 16px;
  }
}

@keyframes showModal {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
