/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  padding: 0 10px;

  overflow: hidden;

  background-color: #f2f2f2;

  border-radius: 10px;

  @media $landscapeTablet {
    padding: 0;
  }
}

.slides {
  padding-bottom: 10px;

  @media $mobile {
    padding-bottom: 50px;
  }
}

.slide {
  position: relative;

  max-width: 650px;
  margin: 0 auto 10px;
  padding: 16px 12px 10px;

  font-family: $primaryFont;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  @media $portraitTablet {
    margin-bottom: 15px;
    padding: 25px;
  }
}

.slide.important {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 5px $brandColor;
}

.slide.isInLive {
  margin-bottom: 12px;
  padding: 14px 12px 18px;

  @media $mobile {
    margin-bottom: 15px;
    padding: 20px 25px;
  }
}

.slide.isInIntro:first-child,
.slide:first-child {
  max-width: none;
  margin-right: -10px;
  margin-left: -10px;
  padding: 20px 20px 18px;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 0 1px #dedede;

  @media $landscapeTablet {
    margin-right: 0;
    margin-left: 0;
    padding: 45px 25px 25px;

    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}

.slide.isInIntro:first-child > div,
.slide:first-child > div {
  max-width: 860px;
  margin-right: auto;
  margin-left: auto;
}

.slide:last-child {
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;

  @media $mobile {
    padding: 18px 25px;
  }
}

.meta {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  margin-bottom: 7px;

  color: #808080;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  @media $mobile {
    font-size: 13px;
    line-height: 16px;
  }
}

.status {
  margin-right: 5px;

  color: $brandColor;
  font-weight: 600;

  font-size: 12px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.author::before {
  display: inline-block;

  padding: 0 4px;

  content: '\B7';
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  transition: opacity 0.5s linear;
}

.top {
  display: none;

  margin: 0;

  font-size: 30px;
  line-height: 50px;

  @media $mobile {
    display: block;
  }
}

.banner {
  @media $landscapeTablet {
    display: none;
  }
}
