@import '../../../styles/vars.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 330px;
  height: 45px;

  color: #fff;
  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 15px;

  text-align: center;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
}

.error {
  color: #fff;

  background: #f95a5a;
}

.info {
  color: #000;

  background: #ebe8e8;
}

.success {
  color: #000;

  background: #7af07d;
}
