/* set default white background for facebook and reddit embeds */
.fb-post,
.embedly-card .embedly-card-hug iframe {
  background-color: #fff;
}

/* Hide ReCaptcha Badge */
.grecaptcha-badge {
  visibility: hidden;
}
