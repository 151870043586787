/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: center;

  @media $wideDesktop {
    padding-top: 2px;

    font-size: 14px;
    line-height: 17px;
  }
}

.icon {
  display: inline-block;

  margin-right: 10px;

  vertical-align: middle;
}

.icon img {
  display: block;

  width: 13px;
  height: 13px;
  margin-top: -3px;

  vertical-align: middle;

  cursor: pointer;

  @media $landscapeTablet {
    width: 15px;
    height: 15px;
  }
}

.text {
  font-weight: 600;

  font-family: $secondaryFont;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: middle;

  cursor: pointer;

  transition: color 0.15s ease-out;
}

.text:hover a {
  color: $brandColor;
}
