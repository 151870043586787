/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  padding: 6px 13px 0;

  color: #7d7d7d;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;
}

.root strong {
  color: #000;

  font-weight: normal;
}

.root + .root {
  padding-top: 15px;

  @media $landscapeTablet {
    padding-top: 25px;
  }
}

.root a {

  color: inherit;
  text-decoration: underline;
}

.valid {
  color: #01d045;
}

.invalid {
  color: #d0011b;
}

.tip {
  color: #999;
}

.isInLogin {
  padding-top: 15px;

  @media $landscapeTablet {
    padding-top: 30px;
  }
}
