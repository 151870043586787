/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: row nowrap;
}

.item {
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 14px;
  letter-spacing: 1px;
  white-space: nowrap;

  @media $landscapeTablet {
    font-size: 14px;
    line-height: 17px;
  }
}

.usd::before,
.eur::before {
  display: inline-block;

  margin-right: 5px;

  color: #999;

  font-weight: 600;
  line-height: 1;
}

.usd::before {
  content: '$';
}

.eur::before {
  content: '\20AC';
}

.brent {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  @media $landscapeTablet {
    justify-content: flex-start;
  }
}

.brent svg {
  width: 8px;
  margin-right: 5px;

  @media $landscapeTablet {
    width: 9px;
  }
}

.item + .item {
  margin-left: 12px;
}

.title {

  color: #999;
  text-transform: uppercase;
}

.isInExchangeBlock .item {
  position: relative;

  flex: 1 0 auto;

  margin: 0;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;

  text-align: center;
}

.isInExchangeBlock .item::after {
  position: absolute;
  top: -2px;
  right: 0;

  width: 1px;
  height: 16px;

  background-color: #d8d8d8;

  content: '';
}

.isInExchangeBlock .item:last-child::after {
  display: none;
}
