/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;

  cursor: pointer;

  user-select: none;
}

.root a {
  color: $brandColor;
}

.control {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;

  border: 0;

  clip: rect(0 0 0 0);
}

.control:checked + .checkbox {
  background-color: $brandColor;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='11' height='9' viewBox='4107 525 11 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M4108.51 529.01l3.273 3.154 4.69-5.664'/%3E%3C/svg%3E");
  border-color: $brandColor;
}

.indicator {
  display: block;

  flex-shrink: 0;

  width: 16px;
  height: 16px;
  margin-right: 7px;

  background-repeat: no-repeat;
  background-position: center;

  border: 1px solid #ccc;
}

.checkbox {
  border-radius: 2px;
}

.text {
  font-size: 13px;
  line-height: 17px;
}
