/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 301;

  display: flex;

  align-items: normal;

  justify-content: center;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);

  @media $contentWidth {
    padding: 0 20px;
  }

  @media $landscapeTablet {
    display: block;
    padding: 0 15px;
  }
}

.modalCenter {
  @media $landscapeTablet {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.modalContainer {
  width: 100%;

  @media $contentWidth {
    position: relative;

    width: 650px;
  }

  @media $landscapeTablet {
    position: static;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
    max-width: 1060px;
    margin-right: auto;
    margin-left: auto;
  }
}

.modalContainerCenter {
  @media $landscapeTablet {
    position: relative;

    display: block;
    width: 650px;
  }
}

.modalContainerCard {
  width: 630px;
}

.modalContent {
  position: relative;

  top: 82px;
  z-index: 1;

  max-width: 650px;
  max-height: 75vh;
  padding: 0;
  overflow-y: auto;

  background-color: #fff;
  border-radius: 12px;

  @media $landscapeTablet {
    position: relative;
    top: 50px;

    flex-flow: row nowrap;
    justify-content: start;
    width: 650px;
    max-height: 80vh;
    padding: 0 20px;
  }
}

.modalContentCenter {
  @media $landscapeTablet {
    top: 14px;
  }
}

.modalContentCard {
  @media $landscapeTablet {
    width: 630px;
  }
}

.modalChapterBlock {
  display: block;
  padding: 10px 20px;

  font-size: 16px;
  text-decoration: none;

  border-bottom: 1px solid #dedede;

  @media $landscapeTablet {
    padding: 10px 0;

    font-size: 20px;
  }
}

.modalChapterBlock:visited,
.modalChapterBlock:hover,
.modalChapterBlock:active {
  color: #000;
}

.modalChapterBlock:first-child {
  padding-top: 20px;
}

.modalChapterBlock:last-child {
  padding-bottom: 20px;

  border-bottom-width: 0;
}

.modalChapterBlock p {
  margin: 0;
}

.modalChapterBlock strong {
  color: inherit;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.modalChapterBlock em {
  font-style: normal;
}

.modalChapterBlock s {
  text-decoration: none;
}

.modalChapterBlock mark {
  background: none;
  box-shadow: none;
}

.itemChapterFirst {
  display: inline;

  color: #000;
  font-weight: 700;
  font-size: 16px;
  font-family: $secondaryFont;
  line-height: 22px;
  text-transform: uppercase;

  @media $landscapeTablet {
    font-size: 20px;
    line-height: 28px;
  }
}

.itemChapterSecond {
  display: inline;

  color: #000;
  font-weight: 400;
  font-size: 16px;
  font-family: $secondaryFont;
  line-height: 22px;

  @media $landscapeTablet {
    font-size: 20px;
    line-height: 28px;
  }
}

.itemChapterSecond::first-letter {
  text-transform: uppercase;
}

.itemSubChapter {
  display: block;

  color: #000;
  font-weight: 400;
  font-size: 16px;
  font-family: $secondaryFont;
  line-height: 22px;

  @media $landscapeTablet {
    font-size: 20px;
    line-height: 28px;
  }
}

.itemSubChapter::first-letter {
  text-transform: uppercase;
}
