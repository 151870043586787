@import '../../../styles/vars.css';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-10;

  width: 100%;
  height: 100%;

  overflow: hidden;

  color: #fff;

  pointer-events: none;
}

.image {
  margin: auto;

  cursor: zoom-in;
}

.image img {
  display: block;

  max-width: 100%;
  max-height: 100vh;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 1);
}

.lightbox {
  display: flex;

  width: 100vw;
  height: 100vh;
}

.control {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 300;

  @media $portraitTablet {
    top: 40px;
    right: 100px;
  }
}

.control button {
  display: block;

  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;

  color: #fff;

  background-color: rgba(0, 0, 0, 0.7);
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.817 29.207v-6.444h-6.463' stroke='%23DBDBDB' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.413 23.166l-6.173 6.172' stroke='%23DBDBDB' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.231 12.344v6.444h6.463' stroke='%23DBDBDB' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.635 18.385l6.173-6.172' stroke='%23DBDBDB' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;

  cursor: pointer;

  transition: border-color 250ms ease;
}

.control button:hover {
  border-color: rgba(255, 255, 255, 0.4);
}

.zoom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;

  display: none;
  width: 100%;

  margin: 0;

  background-color: transparent;

  border-width: 0;
  outline: none;

  appearance: none;

  @media $mobile {
    display: block;

    cursor: zoom-in;

    pointer-events: initial;
  }
}

.dismiss {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  outline: none;

  @media $mobile {
    display: block;
  }
}

.scaled .image,
.scaled .zoom {
  cursor: zoom-out;
}

.visible .image,
.visible .control button {
  pointer-events: initial;
}

.visible .dismiss {
  @media $mobile {
    pointer-events: initial;
  }
}
