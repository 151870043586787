/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndex-10;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 100%;
  max-height: 0;
  overflow: hidden;

  opacity: 0;

  transition: opacity 0.15s ease-out;

  pointer-events: none;

  @media $portraitTablet {
    align-items: center;

    background-color: transparent;
  }
}

.container {
  position: absolute;

  transform: translate3d(0, 10px, 0);

  transition: transform 0.15s ease-out;
}

.footnote {
  width: 305px;

  pointer-events: auto;

  @media $landscapeTablet {
    width: 335px;
    margin: 0;
  }
}

.root.active {
  height: 100%;
  max-height: none;
  overflow: visible;

  opacity: 1;
}

.root.active .container {
  transform: translate3d(0, 10px, 0);

  @media $landscapeTablet {
    transform: translate3d(0, 15px, 0);
  }
}
